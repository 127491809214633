import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { useSelector, useDispatch } from "react-redux";
import "../styles/Dialog.scss"
import * as actionTypes from "../store/actions/actionTypes"
import {assignUser} from "../store/actions/users"


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// const regions = [
//     "Central", "East", "West", "North", "South"
// ]

// const sites = [
//     "Marina Barrage 1 Pumping Station", "Marina Barrage 2 Pumping Station", "HarbourFront Pumping Station",
//     "Gardens By The Bay Pumping Station", "Mount Faber Pumping Station", "Sentosa Pumping Station"
// ]

// const sites1 = [
//     "Bedok Pumping Station", "East Coast Pumping Station", "Changi South Pumping Station",
//     "Changi East Pumping Station", "Tanah Merah Pumping Station", "Marine Parade Pumping Station"
// ]

function Comp() {

    const assignDlg = useSelector((state) => state.dlgs.assignDlg)
    const member = useSelector((state) => state.dlgs.member)
    const locationList = useSelector((state) => state.dashboard.locations)
    const subLocationList = useSelector((state) => state.dashboard.sublocations)

    const dispatch = useDispatch()

    const [selStatus, setSelStatus] = React.useState(0)
    const [subLocationStep, setSubLocationStep] = React.useState(0)

    const [selectedRegion, selectRegion] = React.useState({})
    const [selectedSite, selectSite] = React.useState({})


    const chosseRegion = (index) => {
        let newStatus = { ...selectedRegion }
        if(newStatus["r-" + index])
            newStatus["r-" + index] = false
        else
            newStatus["r-" + index] = true

        selectRegion(newStatus)
    }

    const chosseSite = (pk) => {
        let status = { ...selectedSite }
        status["subL-" + pk] = !status["subL-" + pk]
        selectSite(status)
    }

    const confirmAssign = () => {
        const user_data = {...member.user_data, assigned_locations: selectedRegion, assigned_Slocations: selectedSite}
        dispatch(assignUser(member.pk, user_data))
        setSelStatus(3)        
        setTimeout(() => {
            setSelStatus(0)
        }, 3000);
    }

    const avaiableLocations = []
    const assignedRegion = []
    const assignedSubLocations = []

    Object.keys(selectedRegion).map(key => {
        if (selectedRegion[key]) {
            const selectedLocation = key.split("-")[1] * 1
            assignedRegion.push(locationList.filter(item=>item.pk === selectedLocation)[0].name)
            const subLocations = []
            subLocationList.filter(item => item.location === selectedLocation).map(item => subLocations.push(item))
            avaiableLocations.push(subLocations)
        }
        return true
    })

    Object.keys(selectedSite).map(key => {
        if (selectedSite[key]) {
            const selected = key.split("-")[1] * 1
            assignedSubLocations.push(subLocationList.filter(item=>item.pk === selected)[0].name)
        }
        return true
    })


    const nextStatus = () => {
        if (selStatus === 1 && subLocationStep + 1 < avaiableLocations.length) {
            setSubLocationStep(subLocationStep + 1)
            return
        }
        setSelStatus(selStatus + 1)
    }
    const backStatus = () => {
        if(selStatus === 1 && subLocationStep > 0){
            setSubLocationStep(subLocationStep - 1)
            return
        }
        if (selStatus > 0)
            setSelStatus(selStatus - 1)
    }

    return (
        <div>
            <Dialog
                open={assignDlg}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => { }}
                aria-describedby="alert-dialog-slide-description"
            >
                <div className='dialog-content'>
                    {selStatus < 3 && <img src='/static/svgs/Exit Button.svg' alt="svg" className='btn-exit' onClick={() => dispatch({ type: actionTypes.ASSIGNDLG_CLOSE })} />}
                    {selStatus < 3 && <h1 className='title'>{member.name}</h1>}
                    {selStatus === 0 && <label className='subtitle'>
                        Assign Region
                    </label>}
                    {(selStatus === 1) && <>
                        <label className='description'>
                            Region Assigned: <br /> <span className='result'>{assignedRegion.join(", ")}</span>
                        </label>
                    </>}
                    {selStatus === 3 &&
                        <div className='assignment-success'>
                            <p className='description '>
                                Assignment Successful
                            </p>
                            <img className='avartar' alt="svg" src='/static/svgs/Approved Logo.svg' />
                        </div>}

                    {selStatus < 3 && <img alt="svg" className='avartar' src={member.img} />}

                    {selStatus === 0 && <p className='description'>
                        Click To Select Region To Assign <br />
                        ( May Select Multiple Regions )
                    </p>}
                    {(selStatus === 1) && <p className='description'>
                        Assign Site For <br /> <span className='result'>Central {assignedRegion[subLocationStep]}</span> <br />( May Select Multiple Sites )
                    </p>}
                    {
                        selStatus === 2 && <>
                            <p className='description result'>
                                Confirm Region And Site
                            </p>
                            <p className='description'>
                                Region: <br /> <span className='result'>{assignedRegion.join(" / ")}</span>
                            </p>
                            <div className='description'>
                                Site:
                                {
                                    assignedSubLocations.map((item, i) => {
                                        return <p key={i} className='description result'>
                                            {item}
                                        </p>
                                    })
                                }
                            </div>
                        </>
                    }

                    <div className='select-part'>
                        {selStatus === 0 && locationList.map((item, i) => {
                            return <div key={i} className={'select-item' + (selectedRegion["r-" + item.pk] ? " selected" : "")} onClick={() => chosseRegion(item.pk)}>
                                {item.name}
                            </div>
                        })}

                        {selStatus === 1 && avaiableLocations[subLocationStep] && avaiableLocations[subLocationStep].map((item, i) => {
                            return <div key={i} className={'select-item' + (selectedSite["subL-" + item.pk] ? " selected" : "")} onClick={() => chosseSite(item.pk)}>
                                {item.name}
                            </div>
                        })}

                        {/* {selStatus === 2 && sites1.map((item, i) => {
                            return <div key={i} className={'select-item' + (selectedSite[item] ? " selected" : "")} onClick={() => chosseSite(item)}>
                                {item}
                            </div>
                        })} */}
                    </div>

                    {selStatus < 2 && <label className='btn-next' onClick={nextStatus}>
                        Next
                    </label>}

                    {selStatus === 2 && <label className='btn-next' onClick={confirmAssign}>
                        Confirm Assignment
                    </label>}
                    {selStatus === 2 && <label className='btn-next' onClick={() => setSelStatus(0)}>
                        Assign Again
                    </label>}

                    {selStatus > 0 && selStatus < 3 && <label className='btn-next' onClick={backStatus}>
                        Back
                    </label>}

                </div>
            </Dialog>
        </div>
    );
}

export default Comp
