import React from "react";
import { connect } from "react-redux";
import { authLogin } from "../store/actions/auth";
import { AccessDlgOpen } from "../store/actions/dlgs";
import withRouter from "../withRouter";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "../styles/Main.scss";

import Header from "../components/Header";
class Comp extends React.Component {
  state = {
    username: "",
    password: "",
    theme: createTheme({
      palette: {
        primary: {
          main: "#F7931E",
        },
        secondary: {
          main: "#F9F9F9",
        },
      },
    }),
    logoutFlag: false,
  };
  componentDidMount() {
  };

  selectSubLocation = (location, locked, systemId) => {
    console.log('location: ', location);
    console.log('locked: ', locked)
    console.log('systemId: ', systemId);
    if (!locked)
      this.props.AccessDlgOpen()
    else
      this.props.navigate("/select-site?location=" + location.pk + "&system=" + systemId )
  }

  render() {
    const { theme } = this.state;
    const { systemList, authUser, locationList } = this.props

    const urlParams = new URLSearchParams(window.location.search);
    const systemId = urlParams.get("system")
    const system = systemList.filter(item => item.pk === systemId * 1)[0]

    let assignedLocations = {}
    if (authUser.json_obj && authUser.json_obj.assigned_locations) assignedLocations = authUser.json_obj.assigned_locations


    return (
      <ThemeProvider theme={theme}>
        <div
          style={{ height: "100%", minHeight: "100vh" }}
        >
          <Header history={this.props.history} />
          <div className="header-content">
            <div className="left">
            </div>
            <div className="center">
              <h2>
                Select Region / {system ? system.name : ""}
              </h2>
            </div>
            <div className="right">
              <label onClick={() => this.props.navigate(-1)}>
                Back
              </label>
            </div>
          </div>
          <div className="main-content">
            {locationList.map((item, i) =>
              <div className="region-item" key={i} onClick={this.selectSubLocation.bind(this, item, assignedLocations["r-" + item.pk], systemId)}>
                {item.name}
                {!assignedLocations["r-" + item.pk] && <div className="locked">
                  <img src="/static/svgs/Lock Logo.svg" alt="svg" />
                </div>}
              </div>
            )}

          </div>
        </div>
      </ThemeProvider>
    );
  }
};

const mapStateToProps = state => {
  return {
    systemList: state.dashboard.systems,
    assetList: state.dashboard.assets,
    locationList: state.dashboard.locations,
    subLocationList: state.dashboard.sublocations,
    authUser: state.auth.user
  };
};
const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => dispatch(authLogin(username, password)),
    AccessDlgOpen: () => dispatch(AccessDlgOpen())

  };
};
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Comp));
