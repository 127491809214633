import React from "react";
import { Routes, Route } from "react-router-dom";
import Hoc from "./hoc/hoc";

import Login from "./containers/CA2Login";
import LoginVerifyOTP from "./containers/LoginVerifyOTP";
import Logout from "./containers/LogoutSuccess"
// import Signup from "./containers/Signup";
// import HomepageLayout from "./containers/Home";
import RegisterComplete from "./containers/Register-complete";
import ForgotPwd from "./containers/ForgotPwd";
import ResetPassword from "./containers/Reset-password";
import Register from "./containers/Register";
import ApproveTeamMates from "./containers/ApproveTeamMates";
// import MySystems from "./containers/MySystems";
import MyAssets from "./containers/MyAssets";
import Dashboard from "./containers/Dashboard";
import RadarChart from "./containers/RadarChart";
import MyTeam from "./containers/MyTeam";
import SelectRegion from "./containers/SelectRegion";
import SelectSite from "./containers/SelectSite";
import Reassign from "./containers/Reassign";
import signupSuccess from "./containers/signup-success";
import ReassignSublocation from "./containers/ReassignSublocation";
import VerifyOTP from "./containers/VerifyOTP";
import UserSettings from "./containers/UserSettings";

import TermsCondition from "./containers/TermsCondition"
import PrivacyPolicy from "./containers/PrivacyPolicy"

const BaseRouter = () => (
  <Hoc>
    <Routes>
      <Route path="/login" Component={Login} />
      <Route path="/log/verify-otp" Component={LoginVerifyOTP} />
      <Route path="/logout" Component={Logout} />
      <Route path="/signup" exact Component={Register} />
      <Route path="/register-complete" Component={RegisterComplete} />
      <Route path="/forgot-pwd" Component={ForgotPwd} />
      <Route path="/reset-pwd" Component={ResetPassword} />
      <Route path="/approve-teammates" Component={ApproveTeamMates} />
      <Route path="/mysystems" Component={MyAssets} />
      <Route path="/radarchart" Component={RadarChart} />
      <Route path="/myteam" Component={MyTeam} />
      <Route path="/dashboard" Component={Dashboard} />
      <Route path="/select-region" Component={SelectRegion} />
      <Route path="/select-site" Component={SelectSite} />
      <Route path="/reassign" Component={Reassign} />
      <Route path="/settings" Component={UserSettings} />
      <Route path="/reassign-sublocation" Component={ReassignSublocation} />
      <Route path="/signup-success" Component={signupSuccess} />
      <Route path="/verify-otp" Component={VerifyOTP} />

      <Route path="/terms-condition" Component={TermsCondition} />
      <Route path="/privacy-policy" Component={PrivacyPolicy} />


      <Route path="/" exact Component={Login} />
      {/* <Route path="*" Component={Login} /> */}
    </Routes>
  </Hoc>
);

export default BaseRouter;
