import React from "react";
import { connect } from "react-redux";

// import { Redirect } from "react-router-dom";
// import { authLogin } from "../store/actions/auth";

import { AssignDlgOpen, FilterDlgOpen } from "../store/actions/dlgs";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import withRouter from "../withRouter";
import "../styles/Main.scss";

import Header from "../components/Header";
import MyTeam from "../components/MyTeam";

class Comp extends React.Component {
  state = {

    username: "",
    password: "",
    theme: createTheme({
      palette: {
        primary: {
          main: "#F7931E",
        },
        secondary: {
          main: "#F9F9F9",
        }
      }
    }),
    logoutFlag: false

  };

  componentDidMount() {
    let { logoutFlag } = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    logoutFlag = urlParams.get("logout");
    this.setState({ logoutFlag });
    if (logoutFlag) {
      setTimeout(() => {
        this.setState({ logoutFlag: false })
      }, 2000);
    }
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { username, password } = this.state;
    this.props.login(username, password);
  };

  clickAssign = (item) => {
    console.log("click assign : ", item)
    this.props.AssignDlgOpen(item)
  }

  render() {
    // const { token } = this.props;
    const { theme } = this.state;
    const { userList, authUser, locationList, subLocationList, permisionList, roleList } = this.props;
    let teamList = []
    let permisions = {}

    
    const authRole = roleList.filter(role => role.pk === authUser.user_role)[0]
    if (authRole)
      permisionList.filter(item => authRole.permissions["per-" + item.pk]).map(permission => permisions["p-" + permission.assign_menu] = true)

    userList.map(user => {
      if (!authUser.json_obj || !user.json_obj) return user

      if (user.json_obj.group_id === authUser.json_obj.group_id && user.is_active === true) {

        const avaiableLocations = []
        const assignedRegion = []
        const assignedSubLocations = []
        const selectedRegion = user.json_obj.assigned_locations ? user.json_obj.assigned_locations : {}
        const selectedSite = user.json_obj.assigned_locations ? user.json_obj.assigned_Slocations : {}

        Object.keys(selectedRegion).map(key => {
          if (selectedRegion[key]) {
            const selectedLocation = key.split("-")[1] * 1
            const location = locationList.filter(item => item.pk === selectedLocation)[0]
            if (location)
              assignedRegion.push(location.name)
            const subLocations = []
            subLocationList.filter(item => item.location === selectedLocation).map(item => subLocations.push(item))
            avaiableLocations.push(subLocations)
          }
          return true
        })

        Object.keys(selectedSite).map(key => {
          if (selectedSite[key]) {
            const selected = key.split("-")[1] * 1
            const selectObj = subLocationList.filter(item => item.pk === selected)[0]
            if (selectObj)
              assignedSubLocations.push(selectObj.name)
          }
          return true
        })


        teamList.push({
          pk: user.pk,
          user_data: user.json_obj,
          name: user.username,
          Role: user.role_position,
          Region: "North",
          img: user.avartar && user.avartar.includes("/media/uploads") ? (user.avartar) : '/static/imgs/user-default.png',
          avaiableLocations,
          assignedRegion,
          assignedSubLocations,
          assigned: assignedSubLocations.length > 0,
          assignable: permisions['p-4']
        })
      }
      return user
    })

    return (
      <ThemeProvider theme={theme}>
        <div
          style={{ height: "100%", minHeight: "100vh" }}
        >
          <Header history={this.props.history} />
          <h2 className="header-title">
            My Team
          </h2>
          <div className="header-content">
            <div className="left">
              <h2 className="region-select" onClick={() => this.props.FilterDlgOpen()}>
                Filter By Regions
              </h2>
            </div>
            <div className="center">
              <label>
                Total Count: {`${teamList.length < 10 ? "0" : ""}${teamList.length}`}
              </label>
            </div>
            <div className="right">
              <label onClick={() => this.props.navigate(-1)}>
                Back
              </label>
            </div>
          </div>
          <div className="main-content">
            {teamList.map((item, i) =>
              <MyTeam key={i} index={i} data={item} clickAssign={this.clickAssign.bind(this, item)} history={this.props.history} />
            )}
          </div>
        </div>
      </ThemeProvider>
    );
  }
};

const mapStateToProps = state => {
  return {
    assignDlg: state.dlgs.assignDlg,
    token: state.auth.token,
    authUser: state.auth.user,

    userList: state.dashboard.users,
    roleList: state.dashboard.user_roles,
    locationList: state.dashboard.locations,
    subLocationList: state.dashboard.sublocations,
    permisionList: state.dashboard.permissions
  };
};
const mapDispatchToProps = dispatch => {
  return {
    AssignDlgOpen: (member) => dispatch(AssignDlgOpen(member)),
    FilterDlgOpen: () => dispatch(FilterDlgOpen()),
  };
};
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Comp));
