import React from "react";
import withRouter from "../withRouter";
import { connect } from "react-redux";
import { logout } from "../store/actions/auth";
import AssignRegionDlg from "../components/AssignRegionDlg";
import FilterDlg from "../components/FilterDlg";
import AccessDenyDlg from "../components/AccessDenyDlg";

import "../styles/Layout.scss"

class CustomLayout extends React.Component {

  state = {
  }

  render() {
    const { authenticated } = this.props;
    return (
      <div className="layout-container">
        {this.props.children}
        <div className="footer-div">
          {authenticated ? <ul>
            <li>Help</li>
            <li>Settings</li>
            <li onClick={()=>this.props.navigate("/privacy-policy")}>Privacy Policy</li>
            <li onClick={()=>this.props.navigate("/terms-condition")}>Terms & Condition</li>
          </ul> : <ul>
            <li onClick={()=>this.props.navigate("/privacy-policy")}>Privacy Policy</li>
            <li onClick={()=>this.props.navigate("/terms-condition")}>Terms & Condition</li></ul>}
          {authenticated ? <p>
            This Software Is Developed By Vibtech Genesis
          </p> : <></>}
        </div>
        <AssignRegionDlg />
        <FilterDlg />
        <AccessDenyDlg />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout())
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CustomLayout)
);
