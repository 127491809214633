import React from "react";
import { connect } from "react-redux";
import { authLogin } from "../store/actions/auth";
import { createTheme, ThemeProvider } from '@mui/material/styles';

import "../styles/Main.scss";
import withRouter from "../withRouter";
import Header from "../components/Header";
import { updateUser } from "../store/actions/users";
// import { Redirect } from "react-router-dom";

class Comp extends React.Component {
  state = {
    username: "",
    password: "",
    theme: createTheme({
      palette: {
        primary: {
          main: "#F7931E",
        },
        secondary: {
          main: "#F9F9F9",
        },
      },
    }),
    logoutFlag: false
  };
  componentDidMount() {
    let { logoutFlag } = this.state

    const urlParams = new URLSearchParams(window.location.search)
    let userId = urlParams.get("id")
    if (userId) userId *= 1
    let locationId = urlParams.get("location")
    if (locationId) locationId *= 1

    logoutFlag = urlParams.get("logout")
    this.setState({ logoutFlag, userId, locationId }, () => {
      if (logoutFlag) {
        setTimeout(() => {
          this.setState({ logoutFlag: false })
        }, 2000);
      }
    })
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { username, password } = this.state;
    this.props.login(username, password);
  };

  updateStatus = (index, status) => {
    let { teamRequests } = this.state
    teamRequests[index].status = status
    this.setState({ teamRequests })
  }

  toggleRegion = (user, location) => {
    const json_obj = { ...user.json_obj }
    if (!json_obj.assigned_Slocations["subL-" + location.pk])
      json_obj.assigned_Slocations["subL-" + location.pk] = true
    else
      json_obj.assigned_Slocations["subL-" + location.pk] = false

    this.props.updateUser(user.pk, user.user_role, user.email, user.username, user.company, user.customer_id, '12345', user.role_position, json_obj)
  }

  backHistory = () => {
    this.props.navigate(-1)
  }


  render() {
    const { theme, userId, locationId } = this.state;
    const { userList, authUser, subLocationList } = this.props

    let member = userList.filter(user => user.pk === userId && authUser.json_obj && user.json_obj.group_id === authUser.json_obj.group_id)[0]

    if (!member) member = {}
    const selectedSite = member.json_obj ? member.json_obj.assigned_Slocations : {}

    return (
      <ThemeProvider theme={theme}>
        <div
          style={{ height: "100%", minHeight: "100vh" }}
        >
          <Header history={this.props.history} />
          <div className="header-content">
            <div className="left">
            </div>
            <div className="center"></div>
            <div className="right">
              <label onClick={() => this.backHistory()}>
                Back
              </label>
            </div>
          </div>
          <div className="main-content reassign">

            <p className="description">
              Reassign {member.username}
            </p>

            <img className="avartar" src={member.avartar && member.avartar.includes("/media/uploads") ? (member.avartar) : '/static/imgs/user-default.png'} alt="avartar" />

            <p>
              Ticked Region/s Are User Current Assigned Regions. Tick New Region To Reassign User To New Region, Or Click Into Each Region To Reassign Sites
            </p>

            {subLocationList.filter(subL => subL.location === locationId).map((item, i) =>
              <div className="region-item" key={i}>
                <label>{item.name}</label>
                <div className="select-option" onClick={this.toggleRegion.bind(this, member, item)}>
                  {selectedSite["subL-" + item.pk] && <img src="/static/imgs/check-icon.JPG" alt="Check Option" />}
                </div>
              </div>
            )}

          </div>
        </div>
      </ThemeProvider>
    );
  }
};

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token,
    authUser: state.auth.user,
    userList: state.dashboard.users,
    roleList: state.dashboard.user_roles,
    locationList: state.dashboard.locations,
    subLocationList: state.dashboard.sublocations
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => dispatch(authLogin(username, password)),
    updateUser: (pk, role, email, username, company, customerID, password, rolePosition, userJson) =>
      dispatch(updateUser(pk, role, email, username, company, customerID, password, rolePosition, userJson))
  };
};
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Comp));
