import React from "react";
import {
  Grid
} from "semantic-ui-react";
import { connect } from "react-redux";
// import { Redirect } from "react-router-dom";
import Button from "@mui/material/Button";
import { authSignup } from "../store/actions/auth";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import withRouter from "../withRouter";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import "../styles/Login.scss"


class Register extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      password1: "",
      password2: "",
      company: "",

      theme: createTheme({
        palette: {
          primary: {
            main: "#F7931E",
          },
          secondary: {
            main: "#F9F9F9",
          },
        },
      }),

      uploadFile: null,
      previewUrl: "/static/imgs/user-icon.png",
      logoutFlag: false,
      uploadImgState: false,
      fieldErrors: {},
      groupId: 0
    };

    this.inputFileRef = React.createRef();

  }

  componentDidMount() {
    let { logoutFlag } = this.state
    const urlParams = new URLSearchParams(window.location.search);
    // 
    logoutFlag = urlParams.get("logout")
    this.setState({ logoutFlag })

    if (logoutFlag) {
      setTimeout(() => {
        this.setState({ logoutFlag: false })
      }, 2000);
    }
  };

  handleChange = e => {
    // console.log(e.target.name, e.target.value)
    this.setState({ [e.target.name]: e.target.value });
  };

  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  // http://api/user/verify-user-signup/?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyUGsiOiI0MSJ9.pvZYW-8xoTjc_3_chwoDV4wmiYAjn01-2tMz9ahjQ7M

  handleSubmit = () => {
    // e.preventDefault();
    // this.props.navigate("/register-complete");

    let { username, email, password1, password2, company, uploadFile, fieldErrors, groupId } = this.state;
    const { groupList } = this.props
    fieldErrors = {}

    const suitGroup = groupList.filter(group => email.toLowerCase().includes(`@${group.tag_name.toLowerCase()}`))[0]


    if (!username) fieldErrors.username = true
    if (!(email && (this.validateEmail(email)))) fieldErrors.email = true
    if (!password1) fieldErrors.password1 = true
    if (!password2) fieldErrors.password2 = true
    if (password1 !== password2) {
      fieldErrors.password2 = true
      fieldErrors.password1 = true
    }
    // if (!company) fieldErrors.company = true

    if (Object.keys(fieldErrors).length > 0) {
      this.setState({ uploadImgState: false, fieldErrors })
      return
    }

    this.props.signup(username, email, password1, password2, company, { group_id: suitGroup ? suitGroup.pk : 0 }, uploadFile, (res) => {
      console.log("sugn up response : ", res)
      if (res.success) this.props.navigate("/signup-success")
      // if(res.token)this.props.navigate("/mysystems")
      // else alert(res)
    });

    // this.props.login(username, password);
  };

  updateUploadImgState = (value) => {
    this.setState({ uploadImgState: value });
  };

  openUploadImg = (e) => {
    e.preventDefault();
    this.inputFileRef.current.click();
  }

  setSelectedFile = (uploadFile) => {
    if (uploadFile) {
      const previewUrl = URL.createObjectURL(uploadFile)
      this.setState({ uploadFile, previewUrl })
    } else {
      this.setState({ uploadFile: null, previewUrl: "/static/imgs/user-icon.png" })
    }
  }

  onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      this.setSelectedFile(undefined)
      return
    }
    // I've kept this example simple by using the first image instead of multiple
    this.setSelectedFile(e.target.files[0])
  }

  selectGroup = e => {
    console.log(e.target.value)
    this.setState({ groupId: e.target.value * 1 })
  }

  render() {
    // const { token } = this.props;

    const { username, email, password1, password2, company, theme, logoutFlag, uploadImgState, fieldErrors, previewUrl } = this.state;


    return (
      <ThemeProvider theme={theme}>
        <Grid
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 720, height: '100%' }}>
            {/* {error && <p>{this.props.error.message}</p>} */}
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
              <div className="form-container">
                <div className="up-center-dev flex-center">
                  <img src="/static/imgs/CA2-Logo.png" alt="svg" />
                  <h3 className="sign-up-account">{!uploadImgState ? "Sign Up For An Account" : "Upload Your Profile Picture (Optional)"}</h3>
                </div>
                <div className={"down-center-div flex-center " + (logoutFlag ? "hide" : "")}>
                  {!uploadImgState ? <>
                    <TextField value={username} name="username" error={fieldErrors.username} onChange={this.handleChange.bind(this)} label="Set A Username" required variant="standard" className="custom-field" />
                    <TextField value={email} name="email" error={fieldErrors.email} onChange={this.handleChange.bind(this)} label="Input Email" required variant="standard" className="custom-field" />
                    <TextField value={password1} type="password" name="password1" error={fieldErrors.password1} onChange={this.handleChange.bind(this)} label="Set A Password" required variant="standard" className="custom-field" />
                    <TextField value={password2} type="password" name="password2" error={fieldErrors.password2} onChange={this.handleChange.bind(this)} label="Reenter Password" required variant="standard" className="custom-field" />
                    {/* <TextField value={company} name="company" error={fieldErrors.company} onChange={this.handleChange.bind(this)} label="Role In Company" required variant="standard" className="custom-field" /> */}
                    <br />
                    <div className="sign-in-btn hover-btn">
                      <Button variant="outlined" onClick={() => this.updateUploadImgState(true)}>Next</Button>
                    </div>

                    {/* <label className="next-btn" onClick={() => this.updateUploadImgState(true)}>
                      Next
                    </label> */}

                    <label className="next-btn mt-3" onClick={() => this.props.navigate("/login")}>
                      Back
                    </label>
                  </> : <>
                    <div className="upload-image">
                      <input
                        type="file"
                        ref={this.inputFileRef}
                        onChange={this.onSelectFile}
                      />
                      <img className="user-icon" src={previewUrl} alt="user icon" />
                      <button onClick={this.openUploadImg.bind(this)}>
                        <img src="/static/svgs/Plus Sign For Uploading Profile Picture.svg" alt="Profile" />
                      </button>
                    </div>
                    <label className="next-btn mt-6" onClick={this.handleSubmit}>
                      Complete Sign Up
                    </label>
                  </>
                  }
                  {uploadImgState && <label className="next-btn mt-3" onClick={() => this.updateUploadImgState(false)}>
                    Back
                  </label>}

                </div>
                <div className={"down-center-div flex-center " + (logoutFlag ? "" : "hide")}>
                  <p className="logout-success">
                    You have successfully logged out
                  </p>
                </div>
              </div>
            </Box>
          </Grid.Column>
        </Grid>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token,
    groupList: state.dashboard.groups
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signup: (username, email, password1, password2, company, user_data, adminUser, uploadFile, callback) =>
      dispatch(authSignup(username, email, password1, password2, company, user_data, adminUser, uploadFile, callback)),

  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Register));
