import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import authReducer from "./store/reducers/auth";
import dlgReducer from "./store/reducers/dlgs";
import dashboardReducer from "./store/reducers/dashboard";
import notificationReducer from './store/reducers/notifications';



const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// require('dotenv').config()

const rootReducer = combineReducers({
  auth: authReducer,
  dlgs: dlgReducer,
  dashboard: dashboardReducer,
  notification: notificationReducer
});

const store = createStore(rootReducer, composeEnhances(applyMiddleware(thunk)));

// const app = (
//   <Provider store={store}>
//     <App />
//   </Provider>
// );

// ReactDOM.render(app, document.getElementById("root"));
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
        <App />
    </Provider>
  </React.StrictMode>
);

registerServiceWorker();
