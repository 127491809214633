import React from "react";
import { connect } from "react-redux";
import { AccessDlgOpen } from "../store/actions/dlgs";
import { getSystemStatus } from "../store/actions/systems";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import "../styles/MySystem.scss";

class Comp extends React.Component {
    state = {
        theme: createTheme({
            palette: {
                primary: {
                    main: "#F7931E",
                },
                secondary: {
                    main: "#F9F9F9",
                },
            },
        }),

        unavaliable: true
    };

    componentDidMount() {
        // this.interval = setInterval(() => {
            const { data } = this.props
            if (data && data.pk)
                getSystemStatus(data.pk, (res) => {
                    if (res.data && res.data.status === 'active')
                        this.setState({ unavaliable: false })
                    else
                        this.setState({ unavaliable: true })
                })
        // }, 2000);
    }


    componentWillUnmount() {
        clearInterval(this.interval)
    }

    handleChange = e => {
    };

    handleSubmit = e => {
        e.preventDefault();
    };

    clickItem = (e) => {
        e.preventDefault()
        const { data, locked, AccessDlgOpen, selectAsset } = this.props
        const { unavaliable } = this.state
        console.log("click item : ", data, unavaliable, locked);
        // if (locked || unavaliable)
        //     AccessDlgOpen()
        // else
            selectAsset(data)
    }

    render() {
        const { data, locked } = this.props
        const { theme, unavaliable } = this.state;

        return (
            <ThemeProvider theme={theme}>
                {data && <div className="select-item" onClick={this.clickItem}>
                    {data.name}

                    {/* <div className="status-led">location: {data.slocation_name} <div className={"led " + (unavaliable ? "inactive" : "active")}></div></div> */}
                    <div className="status-led">location: {data.device_location} <div className={"led " + (unavaliable ? "inactive" : "active")}></div></div>

                    {locked && <div className="locked">
                        <img src="/static/svgs/Lock Logo.svg" alt="svg" />
                    </div>}
                </div>}
            </ThemeProvider>
        );
    }
};

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        token: state.auth.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        AccessDlgOpen: () => dispatch(AccessDlgOpen())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Comp);
