import React, { createRef } from "react";
import { connect } from "react-redux";
import { authLogin } from "../store/actions/auth";
import { toggleHeaderMenus, getSystemStatus } from '../store/actions/systems'
import withRouter from "../withRouter";


import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import * as  Indoor from "indoorjs/dist/indoor";
// import ReactMapGL from "react-map-gl";
import Map, { Marker, Popup } from 'react-map-gl';

import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "../styles/Main.scss";
import "../styles/MySystems.scss";
import Header from "../components/Header";
import iconGreenMarker from "../assets/imgs/icon-green-marker.png";
import iconRedMarker from "../assets/imgs/icon-red-marker.png";
import AssetComp from "../components/AssetComp";
const GreenMarker = "/static/svgs/green-marker.svg";
const RedMarker = "/static/svgs/red-marker.svg";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

class Comp extends React.Component {
  state = {
    theme: createTheme({
      palette: {
        primary: {
          main: "#F7931E",
        },
        secondary: {
          main: "#F9F9F9",
        },
      },
    }),
    logoutFlag: false,
    selectedAsset: undefined,
    selectedSLocation: undefined,
    selectedFloorMap: undefined,
    firstLoad: false,

    openFloorPlanFlag: false,
    mapRef: createRef(),
    sLocations: {},
    floorMaps: {},
    floorMarkerPos: { x: 0, y: 0 },

    selectedSubLocation: false,
    selectedGroup: undefined

  };

  componentDidMount() {
    let { logoutFlag } = this.state
    const urlParams = new URLSearchParams(window.location.search);
    logoutFlag = urlParams.get("logout");
    this.setState({ logoutFlag });
    if (logoutFlag) {
      setTimeout(() => {
        this.setState({ logoutFlag: false });
      }, 2000);
    }
  };

  componentDidUpdate(prevProps, prevState) {

    const { subLocationList, locationList, assetList, authUser, groups } = this.props
    let { openFloorPlanFlag, firstLoad } = this.state
    if (firstLoad && prevProps.subLocationList?.length == subLocationList.length && prevProps.locationList?.length == locationList?.length
      && prevProps.assetList?.length == assetList?.length && prevProps.authUser == authUser) return

    const sLocations = {}
    const floorMaps = {}
    subLocationList.map(location => {
      const mapLocation = locationList.find(item => item.pk === location.location)
      // if (authUser.json_obj?.assigned_Slocations) {
        // let assets = assetList.filter(asset => (asset.json_obj.group_id === authUser?.json_obj?.group_id) && (location.pk === asset.sub_location)
        //   && authUser.json_obj?.assigned_Slocations["subL-" + location.pk])
        // let assets = assetList.filter(asset => (asset.json_obj.group_id === authUser?.json_obj?.group_id)
        //   && authUser.json_obj?.assigned_locations["r-" + location.location] && Number(asset.sub_location) === location.pk)
        let assets = assetList.filter(asset => (asset.json_obj.group_id === authUser?.json_obj?.group_id) && Number(asset.sub_location) === location.pk)

        if (assets.length > 0) {
          sLocations["loc-" + location.pk] = { ...location, assets }
          if (mapLocation) {
            if (!floorMaps['floor-' + mapLocation.pk])
              floorMaps['floor-' + mapLocation.pk] = { ...mapLocation, sLocations: [] }

            floorMaps['floor-' + mapLocation.pk].sLocations.push("loc-" + location.pk)

          }

        } else if (mapLocation?.floor_type != 1 && assets.length > 0) {
          sLocations["loc-" + location.pk] = { ...location, assets }
        }
      // }

      return true
    })

    // this.interval = setInterval(() => {
    for (const key in sLocations) {
      const sLocation = sLocations[key];
      if (sLocation.assets && sLocation.assets[0].pk)
        getSystemStatus(sLocation.assets[0].pk, (res) => {
          if (res.data && res.data.status === 'active')
            // sLocation['status'] = 1;
            sLocation.assets[0]['status'] = 1;
          else
            // sLocation['status'] = 0;
            sLocation.assets[0]['status'] = 0;
        })
    }
    // }, 2000);

    const group = groups.find(g => g.pk === authUser?.json_obj?.group_id);
    const assignedLocations = authUser?.json_obj?.assigned_locations;

    if (!openFloorPlanFlag) {
      if (assignedLocations) {
        const rLocationId = Object.keys(assignedLocations)[0];
        const location = locationList.find(l => `r-${l.pk}` === rLocationId);
        if (location) {
          if (location.floor_type === 1) {
            this.setState({
              openFloorPlanFlag: true,
              selectedSLocation: undefined
            }, () => {
              setTimeout(() => {
                const mapKey = Object.keys(floorMaps)[0]
                if (mapKey) {
                  this.selectFloorPlanMap(mapKey)
                }
              }, 300);
            });
          } else {
            this.setState({
              openFloorPlanFlag: false
            }, () => {
              this.setState({ selectedFloorMap: undefined })
            });
          }
        }
      }
    } else {
      if (assignedLocations) {
        let trueKeys = [];
        for (const key in assignedLocations) {
          if (assignedLocations[key] === true) {
            trueKeys.push(key);
          }
        }
        const rLocationId = trueKeys[0];
        const location = locationList.find(l => `r-${l.pk}` === rLocationId);
        if (location) {
          if (location.floor_type === 1) {
            setTimeout(() => {
              const mapKey = Object.keys(floorMaps)[0]
              if (mapKey) {
                this.selectFloorPlanMap(mapKey)
              }
            }, 300);
          } else {
            this.setState({
              openFloorPlanFlag: false
            }, () => {
              this.setState({ selectedFloorMap: undefined })
            });
          }
        }
      }
    }

    this.setState({ sLocations, floorMaps, firstLoad: true });

  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };


  goListAll = () => {
    this.props.navigate("/mysystems")
  }

  openFloorPlan = () => {

    const { hideMenus, subLocationList, assetList, authUser, locationList } = this.props
    let { openFloorPlanFlag } = this.state
    openFloorPlanFlag = !openFloorPlanFlag

    let that = this
    this.setState({ openFloorPlanFlag, selectedSLocation: undefined }, () => {
      if (openFloorPlanFlag) {
        setTimeout(() => {
          const { floorMaps } = that.state
          const mapKey = Object.keys(floorMaps)[0]
          this.selectFloorPlanMap(mapKey)
        }, 300);
      } else {
        this.setState({ selectedFloorMap: undefined })
      }
    })

  }

  selectFloorPlanMap = (mapKey) => {
    const { floorMaps, sLocations } = this.state

    if (!floorMaps[mapKey]?.floor_plan) {
      alert("Please Upload Map Image");
      return
    }
    const mapEl = document.querySelector('#indoor-map>.main');
    mapEl.innerHTML = ""

    const map = new Indoor.Map(mapEl, {
      floorplan: new Indoor.Floor({
        url: floorMaps[mapKey].floor_plan,
        width: 720,
        opacity: 0.5,
        zIndex: -1
      }),
      minZoom: 0.1,
      maxZoom: 100,
      // showGrid: false
    });

    console.log('floorMaps: ', floorMaps);

    floorMaps[mapKey].sLocations?.map(locationKey => {
      const locationItem = sLocations[locationKey];
      if (!((locationItem.pos_lng > -180 && locationItem.pos_lng < 180) && (locationItem.pos_lat > -90 && locationItem.pos_lat < 90))) return false;
      if (locationItem.assets.filter(asset => parseInt(asset.sub_location) === sLocations[locationKey].pk).length < 1) return false;
      // if (!locationItem.status) return false;
      console.log('locationItem.status: ', locationItem.name, locationItem.status);
      const marker = new Indoor.Marker([locationItem.pos_lng, locationItem.pos_lat], {
        selectable: true,
        clickable: true,
        zIndex: 100,
        size: 30,
        icon: { url: (locationItem.assets[0]?.status && locationItem.assets[0]?.status !== 0) ? iconGreenMarker : iconRedMarker },
        id: locationItem.pk
      });

      // eslint-disable-next-line no-loop-func
      marker.on('ready', () => {
        marker.addTo(map);
      });

      marker.on('click', (e) => {
        const floorMarkerPos = { x: (mapEl.clientWidth / 2 + 18 + (marker.position.x - map.center.x) * map.zoom), y: (mapEl.clientHeight / 2 + (map.center.y + marker.position.y) * map.zoom) }
        this.setState({ selectedSLocation: "loc-" + locationItem.pk, floorMarkerPos })
      });

      setTimeout(() => {
        map.setZoom(0.6)
      }, 300);

      return marker
    });

    let radar;



    this.setState({ selectedFloorMap: mapKey })
  }

  goRadarChart = (system) => {

    let { selectedAsset } = this.state
    if (selectedAsset)
      this.props.navigate(`/radarchart?system=${system.pk}&asset=${selectedAsset.pk}`)

  }

  clickMarker = (marker) => {
    setTimeout(() => {
      this.setState({ selectedSLocation: marker })
    }, 200);
  }

  clickAsset = (selectedAsset) => {

    let { systemList, locationList, subLocationList } = this.props
    const systemObjs = systemList.filter(system => selectedAsset.json_obj.systems.includes(system.pk))
    const locationObj = locationList.filter(item => selectedAsset.location * 1 === item.pk * 1)[0]
    const sLocationObj = subLocationList.filter(item => selectedAsset.sub_location * 1 === item.pk * 1)[0]

    this.setState({ selectedAsset: { ...selectedAsset, systemObjs, locationObj, sLocationObj } }, () => {
      // this.toggleMenuHidden(false)
    })
  }

  toggleMenuHidden = (value) => {
    this.props.toggleHeader(value)
    let { openFloorPlanFlag, selectedFloorMap } = this.state

    setTimeout(() => {
      if (openFloorPlanFlag)
        this.selectFloorPlanMap(selectedFloorMap)
      else
        this.state.mapRef.current?.resize()

    }, 200);
  }

  closeFloor = () => {
    this.setState({ selectedSLocation: undefined, selectedAsset: undefined })
  }

  clickFloorTable = (e) => {
    if (e.target == document.querySelector("div.floor-table"))
      this.closeFloor()
  }

  selectGroup = (group) => {
    console.log('group: ', group);
    this.setState({
      selectedGroup: group,
    });
  }

  // selectSubLocation = (location, locked, assetId) => {
  //   console.log('location: ', location.pk);
  //   console.log('locked: ', locked)
  //   console.log('assetId: ', assetId);
  //   // if (!locked)
  //   //   this.props.AccessDlgOpen()
  //   // else
  //   //   this.props.navigate("/select-site?location=" + location.pk + "&system=" + systemId)
  //   this.setState({
  //     selectedSubLocation: true,
  //     locationId: location.pk
  //   });
  // }

  selectAsset = (asset) => {
    console.log('systemUrls: ', Object.values(asset.json_obj.systemUrls)[0]);
    const systemUrls = asset.json_obj?.systemUrls;
    const systemUrl = Object.values(systemUrls)[0]
    if (systemUrl) {
      const target_url = systemUrl.split("#");
      console.log('target_url: ', target_url);
      window.open(`${target_url[0]}?asset=${asset.pk}&token=${this.props.token}&extension=vibro-autofill${target_url[1] ? ("#" + target_url[1]) : ""}`, "_blank");
    }
  }

  render() {

    const { theme, selectedAsset, mapRef, selectedSLocation, openFloorPlanFlag, sLocations, floorMaps, selectedFloorMap, floorMarkerPos, selectedSubLocation, locationId, selectedGroup } = this.state;
    const { hideMenus, subLocationList, assetList, authUser, locationList, groups } = this.props
    let assignedLocations = {}
    if (authUser.json_obj && authUser.json_obj.assigned_locations) assignedLocations = authUser.json_obj.assigned_locations;
    const urlParams = new URLSearchParams(window.location.search);
    const assetId = urlParams.get("asset");

    const location = locationList.filter(item => item.pk === locationId * 1)[0] ? locationList.filter(item => item.pk === locationId * 1)[0] : {}
    const subLocations = subLocationList.filter(item => item.location === locationId * 1)
    // const assets = []
    // subLocations.map(sLocation => {
    //   let asset = assetList.filter(item => (item.sub_location * 1 === sLocation.pk && item.json_obj.systems.includes(assetId * 1)))[0]
    //   if (asset) {
    //     asset.slocation_name = sLocation.name;
    //     assets.push(asset);
    //   }
    //   return asset
    // });

    const assets = assetList.filter(asset => asset?.json_obj?.group_id === authUser?.json_obj?.group_id &&
      ((sLocations['loc-' + asset.sub_location]?.pos_lng > -180 && sLocations['loc-' + asset.sub_location]?.pos_lng < 180) && (sLocations['loc-' + asset.sub_location]?.pos_lat > -90 && sLocations['loc-' + asset.sub_location]?.pos_lat < 90)));

    console.log('selectedAsset: ', selectedAsset);

    return (
      <ThemeProvider theme={theme}>
        <div className="my-systems">
          <Header />
          <div className={"main-content" + (hideMenus ? " hide-menu" : "")} >
            <div className="right-side">

              <div className="button-area" style={{ justifyContent: "flex-start" }}>
                <button className="expand-map close-map" onClick={() => this.toggleMenuHidden(false)}>
                  Close Expand
                </button>
              </div>

              {openFloorPlanFlag ?
                <div id="indoor-map">
                  <div className="main"></div>
                  {selectedSLocation &&
                    <div className="floor-table" onClick={this.clickFloorTable.bind(this)}>
                      <table style={{ left: floorMarkerPos.x, top: floorMarkerPos.y }}
                        className={(sLocations[selectedSLocation].assets[0].status && sLocations[selectedSLocation].assets[0].status !== 0) ? "green-marker" : "red-marker"}>
                        <thead>
                          {
                            sLocations[selectedSLocation].assets.filter(asset => parseInt(asset.sub_location) === sLocations[selectedSLocation].pk).map(asset =>
                              <tr key={asset.pk} onClick={() => this.clickAsset(asset)}>
                                <th>Asset ID</th>
                                <th>{asset?.name}</th>
                              </tr>
                            )
                          }
                        </thead>
                        <tbody>
                          <tr>
                            <td onClick={this.closeFloor}>
                              Close
                            </td>
                            <td>
                              {sLocations[selectedSLocation].assets.filter(asset => parseInt(asset.sub_location) === sLocations[selectedSLocation].pk).length} Assets
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  }
                </div>
                :
                <Map
                  ref={mapRef}
                  initialViewState={{
                    longitude: 103.810715,
                    latitude: 1.377443,
                    zoom: 10.5
                  }}
                  mapStyle="mapbox://styles/mapbox/streets-v9"
                  mapboxAccessToken="pk.eyJ1IjoiaW1hZ2lucmF5IiwiYSI6ImNrN2YwdXV3MDBoYTQzZ280dGYzdG40cmQifQ.EPVSF0iRfdmOey75gDrBLQ">
                  {
                    Object.keys(sLocations).map(key =>
                      (sLocations[key].pos_lng > -180 && sLocations[key].pos_lng < 180) && (sLocations[key].pos_lat > -90 && sLocations[key].pos_lat < 90) && sLocations[key].assets.filter(asset => parseInt(asset.sub_location) === sLocations[key].pk).length > 0 &&
                      <Marker
                        key={key}
                        longitude={sLocations[key].pos_lng}
                        latitude={sLocations[key].pos_lat}
                        anchor="bottom"
                        onClick={() => this.clickMarker(key)}
                      >
                        <img className="marker-item" src={(sLocations[key].assets[0].status && sLocations[key].assets[0].status !== 0) ? GreenMarker : RedMarker} alt="Green Marker" />
                      </Marker>)
                  }
                  {
                    selectedSLocation &&
                    <Popup longitude={sLocations[selectedSLocation].pos_lng} latitude={sLocations[selectedSLocation].pos_lat}
                      anchor="bottom"
                      onClose={() => this.setState({ selectedSLocation: null })}>
                      <table className={(sLocations[selectedSLocation].assets[0].status && sLocations[selectedSLocation].assets[0].status !== 0) ? "green-marker" : "red-marker"}>
                        <thead>
                          {
                            sLocations[selectedSLocation].assets.filter(asset => parseInt(asset.sub_location) === sLocations[selectedSLocation].pk).map(asset =>
                              <tr key={asset.pk} onClick={() => this.clickAsset(asset)}>
                                <th>Asset ID</th>
                                <th>{asset?.name}</th>
                              </tr>
                            )
                          }
                        </thead>
                        <tbody>
                          <tr>
                            <td onClick={() => this.setState({ selectedSLocation: null })}>
                              Close
                            </td>
                            <td>
                              {sLocations[selectedSLocation].assets.filter(asset => parseInt(asset.sub_location) === sLocations[selectedSLocation].pk).length} Assets
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Popup>
                  }
                </Map>
              }

              <div className="button-area">
                <button className="list-all" onClick={() => this.goListAll()}>
                  List All
                </button>
                <button className="btn-radar-chart" onClick={() => this.openFloorPlan()}>
                  {openFloorPlanFlag ? "Global Map" : "Floor Plan"}
                </button>
                <button className="expand-map" onClick={() => this.toggleMenuHidden(true)}>
                  Expand Map
                </button>
              </div>

            </div>

            <div className="left-side reversed">
              {
                selectedAsset ? (<table className={(selectedAsset.status && selectedAsset.status !== 0) ? "default green-marker" : "default red-marker"}>
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>{(selectedAsset.status && selectedAsset.status !== 0) ? "Online" : "Offline"}</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td>Asset ID</td>
                      <td> {selectedAsset?.name}</td>
                    </tr>

                    {
                      selectedAsset?.systemObjs?.map((system, i) => <tr key={system.pk}>
                        <td>
                          System {i + 1}
                        </td>
                        <td>
                          {system.name}
                        </td>
                      </tr>)
                    }

                    <tr>
                      <td>
                        Location
                      </td>
                      <td>
                        {selectedAsset.locationObj.name}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        Sub Location
                      </td>
                      <td>
                        {selectedAsset.sLocationObj.name}
                      </td>
                    </tr>

                  </tbody>
                </table>) : <div>
                  {/* {
                    openFloorPlanFlag ?
                      <div className="table-content">
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 400 }} aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell >Created</StyledTableCell>
                                <StyledTableCell align="right" >Assets</StyledTableCell>
                                <StyledTableCell align="center">Status</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Object.keys(floorMaps)
                                .map((mapKey) => (
                                  <StyledTableRow key={mapKey} onClick={this.selectFloorPlanMap.bind(this, mapKey)} className={mapKey == selectedFloorMap ? "selected" : ""}
                                  >
                                    <StyledTableCell>
                                      {floorMaps[mapKey].name}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {floorMaps[mapKey].created_at.substr(0, 10)}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      {floorMaps[mapKey].sLocations?.length}
                                    </StyledTableCell>
                                    <StyledTableCell align="center" className="success">No Alarm</StyledTableCell>
                                  </StyledTableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                      :
                      <div className="alarm-items">
                        <div className="alarm-item">
                          <img src={GreenMarker} alt="Green Marker" />
                          <label>No Alarms / Notifications</label>
                        </div>
                        <br />
                        <div className="alarm-item">
                          <img src={RedMarker} alt="Red Marker" />
                          <label>There are Alarms / Notifications, <br /><br />Please Check.</label>
                        </div>
                      </div>
                  } */}
                  {
                    // !selectedGroup ?
                    //   <div className="main-content">
                    //     {groups.filter(item => item.pk === authUser?.json_obj?.group_id).map((item, i) =>
                    //       // <div className="region-item" key={i} onClick={this.selectSubLocation.bind(this, item, assignedLocations["r-" + item.pk], assetId)}>
                    //       //   {item.name}
                    //       //   {/* {!assignedLocations["r-" + item.pk] && <div className="locked">
                    //       //     <img src="/static/svgs/Lock Logo.svg" alt="svg" />
                    //       //   </div>} */}
                    //       // </div>
                    //       <div className="region-item" key={i} onClick={this.selectGroup.bind(this, item)}>
                    //         {item.name}
                    //         {/* {!assignedLocations["r-" + item.pk] && <div className="locked">
                    //           <img src="/static/svgs/Lock Logo.svg" alt="svg" />
                    //         </div>} */}
                    //       </div>
                    //     )}
                    //   </div>
                    //   :
                    <div
                      style={{ height: "100%", minHeight: "100vh" }}
                    >
                      <div className="header-content">
                        <div className="left">
                          <label>Status Legend</label>
                          <div className="led-item">
                            <div className="led active"></div>
                            Active
                          </div>
                          <div className="led-item">
                            <div className="led inactive"></div>
                            Inactive
                          </div>
                        </div>
                        <div className="center">
                          <h2>
                            {/* {selectedGroup.name} */}
                            {
                              groups.find(item => item.pk === authUser?.json_obj?.group_id)?.name
                            }
                          </h2>
                        </div>
                        <div className="right" >
                          <label onClick={() => this.setState({ selectedGroup: undefined })}>
                            Back
                          </label>
                        </div>
                      </div>
                      <div className="main-content" style={{ marginTop: 0 }}>
                        {assets.map((item, i) =>
                          <AssetComp key={i} data={item} selectAsset={this.clickAsset} locked={false} />
                        )}
                      </div>
                    </div>
                  }
                </div>
              }

              {
                selectedAsset && <div className="button-area">
                  {
                    selectedAsset?.systemObjs?.map((system, i) =>
                      <button className="btn-waveform" key={system.pk} onClick={() => this.goRadarChart(system)} >
                        {system.description ? system.description : system.name}
                      </button>
                    )
                  }
                </div>
              }

            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
};

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token,
    systemList: state.dashboard.systems,
    assetList: state.dashboard.assets,
    locationList: state.dashboard.locations,
    subLocationList: state.dashboard.sublocations,
    roleList: state.dashboard.user_roles,
    permisionList: state.dashboard.permissions,
    authUser: state.auth.user,
    hideMenus: state.dashboard.hidemenus,
    alarms: state.dashboard.alarms,
    groups: state.dashboard.groups
  };
};
const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => dispatch(authLogin(username, password)),
    toggleHeader: (value) => dispatch(toggleHeaderMenus(value))
  };
};
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Comp));
