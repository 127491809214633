import React, { Component } from "react";
import { BrowserRouter as Router, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import BaseRouter from "./routes";
import * as authActions from "./store/actions/auth";
import * as notificationActions from "./store/actions/notification";

import { getPermissions } from "./store/actions/permission";
import { getRoles } from "./store/actions/user_role";
import { getUsers, getCompanies, getGroups } from './store/actions/users';
import { getAssets } from './store/actions/assets';
import { getAlarms } from "./store/actions/alarms";
import { getVPNs } from './store/actions/vpns';
import { getSystems } from './store/actions/systems';
import { getLocations, getSubLocations } from './store/actions/locations';
import Snackbar from '@mui/material/Snackbar';
import Alert from "./components/Alert";

import "semantic-ui-css/semantic.min.css";
import CustomLayout from "./containers/Layout";

import 'react-svg-radar-chart/build/css/index.css'
import 'mapbox-gl/dist/mapbox-gl.css';
import "./style.scss"

class App extends Component {

  componentDidMount() {
    const { onTryAutoSignup, getPermissionList, getRoleList, getCompanyList, getGroupList, getUserList,
      getAssetList, getVPNList, getLocationList, getSubLocationList, getSystemList, getUserAuth, getAlarmList } = this.props
    onTryAutoSignup();
    const token = localStorage.getItem("token")
    getCompanyList();
    getGroupList();
    getUserList();
    if (token) {
      getUserAuth(token)
      getPermissionList()
      getRoleList()
      getAssetList()
      getVPNList()
      getLocationList()
      getSubLocationList()
      getSystemList()
      getAlarmList()
    }

  }

  componentDidUpdate(prevProps) {
    if (this.props.authUser !== prevProps.authUser) {
      const { getPermissionList, getRoleList, getCompanyList, getGroupList, getUserList,
        getAssetList, getVPNList, getLocationList, getSubLocationList, getSystemList, getAlarmList } = this.props
      getUserList();
      getCompanyList();
      getGroupList();
      if (this.props.isAuthenticated) {
        console.log("get all dataset ...")
        getPermissionList()
        getRoleList()
        getAssetList()
        getVPNList()
        getLocationList()
        getAlarmList()
        getSubLocationList()
        getSystemList()
      }
    }
  }



  render() {

    const { isAuthenticated, closeSuccessSB, closeErrorSB, closeInfoSB, successData, errorData, infoData } = this.props
    const unSignedPages = [
      "/login", "/logout", "/signup", "/register-complete"
      , "/forgot-pwd", "/reset-pwd", "/verify-otp", '/signup-success',
      "/log/verify-otp"
    ]
    const sharedPage = [
      '/terms-condition', '/privacy-policy'
    ]
    if (!isAuthenticated) sharedPage.map(page => { return unSignedPages.push(page) })
    const isSignPage = unSignedPages.includes(window.location.pathname)

    return (
      <Router>
        <CustomLayout {...this.props}>
          <BaseRouter />

          {
            (!isAuthenticated) && (!isSignPage) && <Navigate to="/login" />
          }
          {
            (isAuthenticated) && (isSignPage) && <Navigate to="/dashboard?from=login" />
          }

          <Snackbar open={successData.open} autoHideDuration={6000} onClose={closeSuccessSB}>
            <Alert onClose={closeSuccessSB} severity="success" sx={{ width: '100%' }}>
              {successData.content}
            </Alert>
          </Snackbar>

          <Snackbar open={errorData.open} autoHideDuration={6000} onClose={closeErrorSB}>
            <Alert onClose={closeErrorSB} severity="error" sx={{ width: '100%' }}>
              {errorData.content}
            </Alert>
          </Snackbar>

          <Snackbar open={infoData.open} autoHideDuration={6000} onClose={closeInfoSB}>
            <Alert onClose={closeInfoSB} severity="info" sx={{ width: '100%' }}>
              {infoData.content}
            </Alert>
          </Snackbar>
        </CustomLayout>
      </Router>

    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    successData: state.notification.success,
    errorData: state.notification.error,
    infoData: state.notification.info,
    authUser: state.auth.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(authActions.authCheckState()),
    getUserAuth: (token) => dispatch(authActions.getAuthUser(token)),
    closeSuccessSB: () => dispatch(notificationActions.closeSuccess()),
    closeErrorSB: () => dispatch(notificationActions.closeError()),
    closeInfoSB: () => dispatch(notificationActions.closeInfo()),
    getPermissionList: () => dispatch(getPermissions()),
    getRoleList: () => dispatch(getRoles()),
    getCompanyList: () => dispatch(getCompanies()),
    getGroupList: () => dispatch(getGroups()),
    getAssetList: () => dispatch(getAssets()),
    getVPNList: () => dispatch(getVPNs()),
    getLocationList: () => dispatch(getLocations()),
    getSubLocationList: () => dispatch(getSubLocations()),
    getSystemList: () => dispatch(getSystems()),
    getUserList: () => dispatch(getUsers()),
    getAlarmList: () => dispatch(getAlarms()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
