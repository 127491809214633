import React from "react";
import {
  Grid
} from "semantic-ui-react";
import axios from "axios";
import { connect } from "react-redux";
import { authLogin } from "../store/actions/auth";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import withRouter from "../withRouter";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "../styles/Login.scss";
import { openError, openSuccess } from "../store/actions/notification";


class Comp extends React.Component {
  state = {

    otpCode: "",
    useremail: "",
    otpStatus: 0,
    new_pwd: "",
    confirm_pwd: "",
    fieldErrors: {},

    theme: createTheme({
      palette: {
        primary: {
          main: "#F7931E",
        },
        secondary: {
          main: "#F9F9F9",
        },
      },
    }),

    logoutFlag: false

  };
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const otp_token = urlParams.get('token')
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/user/get-otp/`, {
        otp_token
      })
      .then(res => {
        console.log("get otp res : ", res)     
        this.setState({otpStatus: res.data.otp_status, useremail: res.data.useremail})
      })
      .catch(() => {        
      });    
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };


  verifyOTP = (e) => {
    e.preventDefault();
    let { otpCode} = this.state
    const urlParams = new URLSearchParams(window.location.search)
    const otp_token = urlParams.get('token')
    
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/user/verify-otp/`, {
        otp_code: otpCode, otp_token
      })
      .then(res => {
        console.log("res : ", res)
        this.setState({otpStatus: res.data.otp_status})
        
      })
      .catch(() => {
        setTimeout(() => {
          this.props.openError(true, "Verification Failed!", "There was an error happened while verifying OTP code.", "Just now")
        }, 300);
      });
    // this.props.navigate("/reset-pwd")
  }

  resetPwd = (e) => {
    e.preventDefault();
    const urlParams = new URLSearchParams(window.location.search)
    const otp_token = urlParams.get('token')
    let {new_pwd, confirm_pwd, fieldErrors} = this.state
    fieldErrors = {}
    if(new_pwd.length<8){
      fieldErrors.new_pwd = true
      this.props.openError(true, "Invalid Password", "There should be more than 8 charactors", "Just now")
    }

    if(new_pwd !== confirm_pwd){
      fieldErrors.new_pwd = true
      fieldErrors.confirm_pwd = true
      this.props.openError(true, "Password Mismatch", "New password does not match with the confirm.", "Just now")
    }

    if(Object.keys(fieldErrors).length > 0){
      this.setState({fieldErrors})
      return
    }

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/user/reset-pwd/`, {
        otp_token,  new_pwd
      })
      .then(res => {
        console.log("res : ", res)
        this.props.openSuccess(true, "Congratulations!", "New password has been updated successfully.", "Just now")    
        setTimeout(() => {
          this.props.navigate("/login") 
        }, 3000);
      })
      .catch(() => {
          this.props.openError(true, "Reset Failed!", "There was an error happened while updating new password.", "Just now")
      });
  }


  render() {
    const { error } = this.props;
    const { theme, logoutFlag, otpCode, useremail, otpStatus, new_pwd, confirm_pwd, fieldErrors } = this.state;


    return (
      <ThemeProvider theme={theme}>
        <Grid
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 720, height: '100%' }}>
            {error && <p>{this.props.error.message}</p>}
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
              <div className="form-container">
                <div className="up-center-dev flex-center">
                  <img src="/static/imgs/CA2-Logo.png" alt="svg" />
                  <h3 className="client-access">Verify OTP</h3>
                  <p className="client-access-below ">Provide us your otp code and we will send you a link to reset your password</p>
                </div>
                <div className={"down-center-div flex-center " + (logoutFlag ? "hide" : "")}>
                  <br />
                  <p className="user-email">{`Email: ${useremail}`}</p>

                  {
                    otpStatus===1?<>
                      <TextField label="New Password" type="password" name="new_pwd" variant="standard" className="custom-field" error={fieldErrors.new_pwd}  value={new_pwd} onChange={this.handleChange} />
                      <TextField label="Confirm Password" type="password" name="confirm_pwd" variant="standard" className="custom-field" error={fieldErrors.confirm_pwd} value={confirm_pwd} onChange={this.handleChange} />
                      <br />
                      <div className="sign-in-btn hover-btn">
                        <Button variant="outlined" onClick={this.resetPwd.bind(this)}>Verify</Button>
                      </div>
                    </>:
                    <>
                     <TextField label="Code" name="otpCode" variant="standard" className="custom-field" value={otpCode} onChange={this.handleChange} />
                      <br />
                      <div className="sign-in-btn hover-btn">
                        <Button variant="outlined" onClick={this.verifyOTP.bind(this)}>Verify</Button>
                      </div>
                    </>
                  }
                  <br />
                  <br />

                  <label className="next-btn" onClick={() => this.props.navigate("/login")}>
                    Go To Login
                  </label>

                </div>
              </div>
            </Box>
          </Grid.Column>
        </Grid>
      </ThemeProvider>
    );
  }
};
const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token
  };
};
const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => dispatch(authLogin(username, password)),
    openError: (open, title, content, timeStr) => dispatch(openError(open, title, content, timeStr)),
    openSuccess: (open, title, content, timeStr) => dispatch(openSuccess(open, title, content, timeStr))
  };
};
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Comp));
