import React, { createRef } from "react";
import { connect } from "react-redux";
import { authLogin } from "../store/actions/auth";
import { toggleHeaderMenus, getSystemStatus } from '../store/actions/systems'
import withRouter from "../withRouter";

import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler } from 'chart.js';
ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler);

import { fft } from 'fft-js'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "../styles/Main.scss";
import "../styles/RadarChart.scss";
import Header from "../components/Header";

import { jsPDF } from "jspdf";

const dataSets = [
  '1ee40ab4-a3c6-48f6-8916-7e3d54cad7ba',
  'f0da854f-6fe5-40c4-8e54-01090694516b',
  'c52154bf-09b4-45f8-a159-299eab346b32',
  '83c906d8-fcb1-41f7-820c-0ff1ab7251ea',
  'cfa3eeda-25aa-4dc4-af1a-9a6e52ebde0d',
  '02d97eba-b428-4d18-b653-acb0647bde5a',
  '9034da20-31ea-405a-8fbd-cefb7957914d',
  'bc979213-61bd-41ee-908c-40cfcdf31d8b',
  '977badb5-8ea8-48e8-a501-07a751a7b813',
  '6ba6fe54-e1ba-4468-bbc3-aefea44735f9'
]


const alarmKeys = [
  "Normal",
  "Notification",
  "Critical",
  "Lethal"
]

const axisData = [
  { id: "unbalance", label: "Unbalance" },
  { id: "misalignment", label: "Misalignment" },
  { id: "motorFault", label: "Motor Fault" },
  { id: "bearing", label: "Bearing" },
  { id: "shaftSway", label: "Shaft Sway" },
  { id: "looseness", label: "Looseness" },
  { id: "vanePass", label: "Vane Pass" }
]

class Comp extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = createRef();
  }
  state = {
    theme: createTheme({
      palette: {
        primary: {
          main: "#F7931E",
        },
        secondary: {
          main: "#F9F9F9",
        },
      },
    }),

    pageLoad: false,
    logoutFlag: false,
    selectedAsset: undefined,
    updateFlag: false,

    markerList: [
      { lng: 103.8, lat: 1.4, id: 0, assetId: "TP001", systems: ["VMS", "MCSA"], type: "Notification, Alert", status: 1 },
      { lng: 103.85, lat: 1.38, id: 1, assetId: "TP002", systems: ["VMS", "MCSA"], type: "Notification, Alert", status: 1 },
      { lng: 103.76, lat: 1.43, id: 2, assetId: "TP003", systems: ["VMS", "MCSA"], type: "Notification, Alert", status: 1 },
      { lng: 103.74, lat: 1.3, id: 3, assetId: "TP004", systems: ["MCSA"], type: "Notification, Alert", status: 0 },
      { lng: 103.7, lat: 1.35, id: 4, assetId: "TP005", systems: ["VMS"], type: "Notification, Alert", status: 1 },
      { lng: 103.89, lat: 1.33, id: 5, assetId: "TP006", systems: ["VMS", "MCSA"], type: "Notification, Alert", status: 1 },
    ],

    chartData: {
      labels: [
        'Unbalance',
        'Misalignment',
        'Motor Fault',
        'Bearing',
        'Shaft Sway',
        'Looseness',
        'Vane Pass'
      ],
      datasets: [
        {
          label: 'Magnitude',
          data: [0, 0, 0, 0, 0, 0, 0],
          fill: false,
          backgroundColor: 'rgba(191, 220, 36, 0.2)',
          borderColor: 'rgb(20, 30, 235)',
          pointBackgroundColor: 'rgb(54, 162, 235)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(54, 162, 235)'
        },

        {
          label: 'Normal',
          data: [30, 30, 30, 30, 30, 30, 30],
          fill: true,
          backgroundColor: 'rgba(146, 208, 80, 0.6)',
          pointBackgroundColor: 'rgb(191, 220, 36)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(54, 162, 235)'
        },

        {
          label: 'Notification',
          data: [50, 50, 50, 50, 50, 50, 50],
          fill: true,
          backgroundColor: 'rgba(191, 220, 36, 0.6)',
          pointBackgroundColor: 'rgb(191, 220, 36)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(54, 162, 235)'
        },
        {
          label: 'Critical',
          data: [80, 80, 80, 80, 80, 80, 80],
          fill: true,
          backgroundColor: 'rgba(255, 188, 68, 0.6)',
          pointBackgroundColor: 'rgb(255, 99, 132)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(255, 99, 132)'
        },
        {
          label: 'Lethal',
          data: [90, 90, 90, 90, 90, 90, 90],
          fill: true,
          backgroundColor: 'rgba(255, 128, 128, 0.6)',
          pointBackgroundColor: 'rgb(255, 99, 132)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(255, 99, 132)',
          tension: 0.3
        }
      ],
    },


    options: {
      elements: {
        line: {
          borderWidth: 1,
          color: 'rgba(255, 99, 132, 0.2)',
        }
      },
      plugins: {
        legend: {
          display: false,
          labels: {
          },
          position: 'right'
        },
        title: {
          display: false,
          text: 'Custom Chart Title',
          padding: {
            top: 6,
            bottom: 6
          }
        },
        filler: {
          propagate: true
        },
        'samples-filler-analyser': {
          target: 'chart-analyser'
        }
      },
      scales: {
        r: {
          angleLines: {
            color: 'white'
          },
          grid: {
            color: 'white'
          },
          pointLabels: {
            color: 'white',
            font: {
              size: 18
            }
          },
          ticks: {
            color: 'rgba(255, 255, 255, 0.85)',
            backdropColor: 'rgba(0, 0, 0, 0.02)',
            font: {
              size: 18
            },
            z: 1
          }
        }
      },
      interaction: {
        intersect: false
      }
    },
    colorLabels: [
      { color: "rgb(146, 208, 80)", label: "Normal" },
      { color: "rgba(191, 220, 36)", label: "Notification" },
      { color: "rgba(255, 188, 68)", label: "Alarm" },
      { color: "rgb(255, 128, 128)", label: "Critical" }
    ],
    systemID: -1,
    unavaliable: true
  };

  componentDidMount() {
    this.setState({ pageLoad: false })
  };

  componentWillUnmount() {
    this.props.toggleHeader(false)
    clearInterval(this.interval)
  }

  componentDidUpdate(prevProps) {

    const urlParams = new URLSearchParams(window.location.search);
    let assetID = urlParams.get("asset");
    let systemID = urlParams.get("system");

    let { assetList, systemList, locationList, subLocationList, alarmList } = this.props;
    let { pageLoad } = this.state;
    if ((pageLoad && assetList?.length === prevProps.assetList?.length && systemList?.length === prevProps.systemList?.length
      && locationList?.length === prevProps.locationList?.length && subLocationList?.length === prevProps.subLocationList?.length
      && alarmList?.length === prevProps.alarmList?.length
    ) || (assetList?.length < 1) || (systemList?.length < 1) || (locationList?.length < 1) || (subLocationList?.length < 1) || (alarmList?.length < 1)) return

    if (assetID)
      assetID = assetID * 1

    const selectedAsset = assetList.filter(asset => asset.pk * 1 === assetID)[0]

    if (!selectedAsset) return

    // this.interval = setInterval(() => {
    if (selectedAsset && selectedAsset.pk)
      getSystemStatus(selectedAsset.pk, (res) => {
        if (res.data && res.data.status === 'active')
          this.setState({ unavaliable: false })
        else
          this.setState({ unavaliable: true })
      })
    // }, 2000);

    const systemObjs = systemList.filter(system => selectedAsset.json_obj.systems.includes(system.pk))

    if (systemID)
      systemID = systemID * 1
    else
      systemID = systemObjs[0]?.pk

    const locationObj = locationList.filter(item => selectedAsset.location * 1 === item.pk * 1)[0]
    const sLocationObj = subLocationList.filter(item => selectedAsset.sub_location * 1 === item.pk * 1)[0]
    this.setState({ selectedAsset: { ...selectedAsset, systemObjs, locationObj, sLocationObj }, systemID, pageLoad: true }, () => {
      this.props.toggleHeader(true)
      this.drawChart()
    })

  }


  drawChart() {

    const { alarmList } = this.props
    const { selectedAsset, chartData, systemID } = this.state
    console.log('selectedAsset: ', selectedAsset);
    let alarmId = selectedAsset?.json_obj?.systemUrls["alarm_id-" + systemID]

    if (alarmId) {
      const alarmObj = alarmList.find(item => item.pk == alarmId)
      console.log("alarm obj: ", alarmId)

      let newData = { ...chartData }
      newData.labels = []
      newData.datasets.map(sets => {
        sets.data = []
      })

      axisData.map(axis => {
        if (alarmObj.json_obj[axis.id] > 0) {
          newData.labels.push(axis.label)
          newData.datasets[0].data.push(0)
          alarmKeys.map((key, index) => {
            newData.datasets[index + 1].data.push(alarmObj.json_obj[`${axis.id}T`][key])
          })
          return true
        }
        return false
      })

      console.log("newData: ", newData)
      this.setState({ chartData: newData })
    }

    // fetch(process.env.REACT_APP_SERVER_URL + "/api_epmmos/Devicelist", {
    //   method: 'GET',
    //   headers: {
    //     'Accept': 'application/json'
    //   },
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     console.log('data: ', data);
    //   })

    // console.log('dataSets: ', dataSets);
    // console.log('systemID: ', systemID, systemID % 10);
    // console.log('HERE: ', `https://genesis-cloud.dyndns.org/signal?id=${dataSets[systemID % 10]}`);

    fetch(`https://genesis-cloud.dyndns.org/signal?id=${dataSets[systemID % 10]}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        let { chartData } = this.state
        let signals = data.data[2]?.Channels[0]?.Waveform;
        if (signals && signals.length > 0) {
          const logs = Math.floor(Math.log2(signals.length));
          signals = signals.slice(0, 2 ** logs);
          const out = fft(signals);
          let realOut = []
          realOut = out.map(item => Math.round(100 * Math.abs(item[0] / out[0][0])) > 90 ? 90 : Math.round(90 * Math.abs(item[0] / out[0][0])));
          realOut = realOut.slice(2, 2 + chartData.labels.length);
          const newChartData = { ...chartData }
          newChartData.datasets[0].data = realOut;
          this.setState({ chartData: newChartData, updateFlag: true });
        }
      })
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  goListAll = () => {
    this.props.navigate("/mysystems");
  }

  backToMap = () => {
    const { authUser, locationList } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    let assetID = urlParams.get("asset");
    console.log('assetID: ', assetID);
    let assignedLocations = {}
    if (authUser.json_obj && authUser.json_obj.assigned_locations) assignedLocations = authUser.json_obj.assigned_locations
    console.log('assignedLocations: ', assignedLocations);
    // return;
    // if (assetID) {
    //   this.props.navigate(`/dashboard?asset=${assetID}`);
    // } else {
    this.props.navigate(`/dashboard`);
    // }
  }

  toggleMenuHidden = (value) => {
    this.props.toggleHeader(value);
  }

  selectSystem = system => {
    const { selectedAsset } = this.state
    // console.log("system: ", system)
    // if (selectedAsset.json_obj?.systemUrls) {
    //   const systemUrl = selectedAsset.json_obj?.systemUrls["system_url-" + system.pk]
    //   if (systemUrl)
    //     window.open(systemUrl, '_blank').focus();
    // }

    if (selectedAsset.json_obj?.systemUrls) {
      const systemUrls = selectedAsset.json_obj?.systemUrls;
      const systemUrl = systemUrls["system_url-" + system.pk]
      if (systemUrl) {
        const target_url = systemUrl.split("#");
        console.log('target_url: ', target_url);
        window.open(`${target_url[0]}?asset=${selectedAsset.pk}&token=${this.props.token}&extension=vibro-autofill${target_url[1] ? ("#" + target_url[1]) : ""}`, "_blank");
      }
    }
  }

  drawSystem = (id) => {
    console.log("draw id: ", id)
    const { systemID } = this.state
    if (id == systemID) return
    this.setState({ systemID: id, updateFlag: false }, () => {
      this.drawChart()
    })
  }

  printPDF = async () => {
    const { selectedAsset } = this.state;
    console.log("print PDF: ")
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await document.querySelector(".main-content");
    pdf.html(data).then(() => {
      pdf.save(`${selectedAsset?.name}.pdf`);
    });
  }

  render() {
    const { theme, chartData, options, selectedAsset, updateFlag, colorLabels, systemID, unavaliable } = this.state;
    const { hideMenus, systemList } = this.props

    return (
      <ThemeProvider theme={theme}>
        <div className="radar-chart">
          <Header history={this.props.history} />

          <div className={"main-content" + (hideMenus ? " hide-menu" : "")} >
            <div className="left-side alarm-items">
              {
                selectedAsset &&
                <table className={!unavaliable ? "green-marker" : "red-marker"}>
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>{!unavaliable ? "Online" : "Offline"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Asset ID</td>
                      <td> {selectedAsset?.name}</td>
                    </tr>
                    {
                      selectedAsset?.systemObjs?.map((system, i) => <tr key={system.pk} className={`${systemID == system.pk ? "selected" : ""}`}
                        onClick={this.drawSystem.bind(this, system.pk)}>
                        <td>
                          System {i + 1}
                        </td>
                        <td >
                          {system.description ? system.description : system.name}
                        </td>
                      </tr>)
                    }
                    <tr>
                      <td>
                        Location
                      </td>
                      <td>
                        {selectedAsset?.locationObj?.name}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        Sub Location
                      </td>
                      <td>
                        {selectedAsset?.sLocationObj?.name}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        Type
                      </td>
                      <td>
                        Standard
                      </td>
                    </tr>

                  </tbody>
                </table>
              }
              <div className="marker-list">
                {
                  selectedAsset?.systemObjs?.map((system, i) =>
                    <div className="marker-item" key={system.pk}
                      onClick={() => this.selectSystem(system)}>
                      {system.logo_img ? <img src={system.logo_img} alt={system.name} /> : <label>{system.description ? system.description : system.name}</label>}
                    </div>
                  )
                }
              </div>

              
              <div className="legend-items">
                {
                  selectedAsset?.radar_status && (
                  colorLabels.map(item => <div className="legend-item" key={item.label}>
                    <div className="color-mark" style={{ background: item.color }}>
                    </div>
                    <label style={{ color: item.color }}>
                      {item.label}
                    </label>
                  </div>)
                  )
                }
              </div>

              <div className="button-area">
                <button className="list-all" onClick={() => this.goListAll()}>
                  List All
                </button>
                <button className="btn-radar-chart" onClick={() => this.backToMap()}>
                  Back To Map
                </button>
              </div>

            </div>
            
            <div className="right-side" >
              {
                selectedAsset?.radar_status && (
                  <>
                    <div style={{ width: 660 }}>
                      <Radar ref={this.chartRef} data={chartData} options={options} redraw={updateFlag} />
                    </div>
                    <div className="btn-systems">
                      {
                        selectedAsset?.systemObjs?.map((system, i) =>
                          <button key={system.pk} className={`${systemID == system.pk ? "selected" : ""}`} onClick={() => this.drawSystem(system.pk)} >
                            {system.description ? system.description : system.name}
                          </button>
                        )
                      }
                    </div>
                    <div className="btn-systems" onClick={() => this.printPDF()}>
                      <button className="selected" style={{ marginTop: '4px', backgroundColor: 'red' }}>
                        Print
                      </button>
                    </div>
                  </>
                )
              }
              
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
};

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token,
    systemList: state.dashboard.systems,
    assetList: state.dashboard.assets,
    locationList: state.dashboard.locations,
    subLocationList: state.dashboard.sublocations,

    roleList: state.dashboard.user_roles,
    permisionList: state.dashboard.permissions,
    authUser: state.auth.user,
    hideMenus: state.dashboard.hidemenus,
    alarmList: state.dashboard.alarms

  };
};
const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => dispatch(authLogin(username, password)),
    toggleHeader: (value) => dispatch(toggleHeaderMenus(value))
  };
};
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Comp));
