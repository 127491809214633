import React from "react";
// import axios from "axios";
import { connect } from "react-redux";
import { authLogin } from "../store/actions/auth";
import { AccessDlgOpen } from "../store/actions/dlgs";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "../styles/Main.scss";
import withRouter from "../withRouter";

import Header from "../components/Header";
import AssetComp from "../components/AssetComp";

class Comp extends React.Component {
  state = {
    username: "",
    password: "",
    theme: createTheme({
      palette: {
        primary: {
          main: "#F7931E",
        },
        secondary: {
          main: "#F9F9F9",
        },
      },
    }),
    logoutFlag: false,
    activeStatus: false
  };

  selectAsset = (asset) => {
    if (asset.app_url) {
      const target_url = asset.app_url.split("#");
      window.open(`${target_url[0]}?asset=${asset.pk}&token=${this.props.token}&extension=vibro-autofill${target_url[1] ? ("#" + target_url[1]) : ""}`, "_blank");
    }
  }

  render() {

    const { theme } = this.state
    const { locationList, subLocationList, assetList } = this.props

    const urlParams = new URLSearchParams(window.location.search)
    const locationId = urlParams.get("location")
    const systemId = urlParams.get("system")


    const location = locationList.filter(item => item.pk === locationId * 1)[0] ? locationList.filter(item => item.pk === locationId * 1)[0] : {}
    const subLocations = subLocationList.filter(item => item.location === locationId * 1)
    const assets = []

    subLocations.map(sLocation => {
      let asset = assetList.filter(item => (item.sub_location * 1 === sLocation.pk && item.json_obj.systems.includes(systemId * 1)))[0]
      if (asset) {
        asset.slocation_name = sLocation.name;
        assets.push(asset);
      }
      return asset
    })


    return (
      <ThemeProvider theme={theme}>
        <div
          style={{ height: "100%", minHeight: "100vh" }}
        >
          <Header history={this.props.history} />
          <div className="header-content">
            <div className="left">
              <label>Status Legend</label>
              <div className="led-item">
                <div className="led active"></div>
                Active
              </div>
              <div className="led-item">
                <div className="led inactive"></div>
                Inactive
              </div>
            </div>
            <div className="center">
              <h2>
                {location.name}
              </h2>
            </div>
            <div className="right" >
              <label onClick={() => this.props.navigate(-1)}>
                Back
              </label>
            </div>
          </div>
          <div className="main-content" style={{ marginTop: 0 }}>
            {assets.map((item, i) =>
              <AssetComp key={i} data={item} selectAsset={this.selectAsset} locked={false} />
            )}
          </div>
        </div>
      </ThemeProvider>
    );
  }
};

const mapStateToProps = state => {
  return {
    assetList: state.dashboard.assets,
    locationList: state.dashboard.locations,
    systemList: state.dashboard.systems,
    subLocationList: state.dashboard.sublocations,
    authUser: state.auth.user,
    token: state.auth.token
  };
};
const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => dispatch(authLogin(username, password)),
    AccessDlgOpen: () => dispatch(AccessDlgOpen())
  };
};
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Comp));
