import React from "react";
import { connect } from "react-redux";
import { logout } from "../store/actions/auth";
import { AccessDlgOpen } from "../store/actions/dlgs";
import withRouter from "../withRouter";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "../styles/Header.scss";


class Comp extends React.Component {
  state = {

    theme: createTheme({
      palette: {
        primary: {
          main: "#F7931E",
        },
        secondary: {
          main: "#F9F9F9",
        },
      },
    }),
    navigate: "",
    headerMenus: [
      {
        title: "Manage My Team",
        img: <svg id="Layer_2" data-name="Layer 2" width="427" height="427" viewBox="0 0 427 427">
          <g id="people">
            <rect id="Rectangle_12" data-name="Rectangle 12" width="427" height="427" fill="#fff" opacity="0" />
            <path id="Path_39" data-name="Path 39" d="M77.4,147.8A72.4,72.4,0,1,0,5,75.4,72.4,72.4,0,0,0,77.4,147.8Z" transform="translate(85.498 51.299)" fill="#fff" />
            <path id="Path_40" data-name="Path 40" d="M68.3,115.6A54.3,54.3,0,1,0,14,61.3,54.3,54.3,0,0,0,68.3,115.6Z" transform="translate(239.394 119.697)" fill="#fff" />
            <path id="Path_41" data-name="Path 41" d="M345.892,139.538a18.1,18.1,0,0,0,18.1-18.1A90.5,90.5,0,0,0,218.109,49.946,126.7,126.7,0,0,0,2,139.538a18.1,18.1,0,0,0,18.1,18.1H237.294a18.1,18.1,0,0,0,18.1-18.1" transform="translate(34.199 222.453)" fill="#fff" />
          </g>
        </svg>
        ,
        link: "/myteam",
        alert: 0,
        enable: true
      },
      {
        title: "New Teammate Request",
        img: <svg version="1.1" id="Capa_1" x="0px" y="0px"
          viewBox="0 0 457.508 457.508" style={{ enableBackground: "new 0 0 457.508 457.508" }}
        >
          <g>
            <path d="M199.671,242.677c66.985,0,121.319-54.353,121.319-121.271C320.99,54.363,266.666,0,199.671,0
         C132.638,0,78.38,54.373,78.38,121.406C78.38,188.324,132.628,242.677,199.671,242.677z" fill="#F9F9F9" />
            <path d="M353.006,275.974c-6.407-13.722-14.478-27.521-24.786-40.688c-5.546-7.057-15.663-14.803-25.445-20.109
         c-25.36,28.419-62.118,46.416-103.104,46.416c-40.956,0-77.705-17.997-103.036-46.35c-9.562,5.432-19.412,13.178-24.776,20.215
         c-17.404,22.711-44.265,66.728-48.549,126.044c-0.899,12.518,5.059,31.633,15.52,38.929
         c19.852,13.895,62.672,31.671,151.833,31.671c30.868,0,56.878-1.903,78.862-4.867c-8.874-14.965-14.066-32.369-14.066-50.997
         C255.449,321.836,298.968,277.504,353.006,275.974z" fill="#F9F9F9" />
            <path d="M353.006,294.946c-44.887,0-81.281,36.395-81.281,81.281c0,44.886,36.395,81.28,81.281,81.28s81.281-36.395,81.281-81.28
         C434.287,331.341,397.901,294.946,353.006,294.946z M393.13,391.183h-31.355v31.365c0,5.192-4.217,9.399-9.409,9.399
         s-9.41-4.207-9.41-9.399v-31.355l-31.354-0.01c-5.202,0.01-9.41-4.197-9.4-9.391c0-5.201,4.207-9.409,9.4-9.409h31.364v-31.355
         c0-5.192,4.208-9.399,9.4-9.391c5.183,0,9.399,4.198,9.409,9.4v31.346h31.346c5.192,0,9.4,4.208,9.41,9.41
         C402.53,386.976,398.322,391.183,393.13,391.183z" fill="#F9F9F9" />
          </g>

        </svg>,
        link: "/approve-teammates",
        alert: 2,
        enable: true
      },
      {
        title: "Assigned Assets",
        img: <svg version="1.1" id="Capa_1" x="0px" y="0px"
          viewBox="0 0 60 60" style={{ enableBackground: "new 0 0 60 60" }}>
          <g>
            <path d="M42,15.5H5.93C2.66,15.5,0,18.16,0,21.429V42.57c0,3.27,2.66,5.93,5.93,5.93H12v10c0,0.413,0.254,0.784,0.64,0.933
         C12.757,59.478,12.879,59.5,13,59.5c0.276,0,0.547-0.115,0.74-0.327L23.442,48.5H42c3.27,0,5.93-2.66,5.93-5.929V21.43
         C47.93,18.16,45.27,15.5,42,15.5z" fill="#F9F9F9" />
            <path d="M54.072,0.57L19.93,0.5C16.66,0.5,14,3.16,14,6.43v6.122c0,0.266,0.105,0.52,0.293,0.708
         c0.188,0.187,0.442,0.292,0.707,0.292c0,0,0.001,0,0.002,0L40.07,13.5c8.951,0,9.93,2.021,9.93,7.93v21.141
         c0,0.091-0.01,0.181-0.014,0.271l1.274,1.401c0.193,0.212,0.463,0.327,0.74,0.327c0.121,0,0.243-0.022,0.361-0.067
         C52.746,44.354,53,43.983,53,43.57v-10h1.07c3.27,0,5.93-2.66,5.93-5.929V6.5C60,3.23,57.34,0.57,54.072,0.57z" fill="#F9F9F9" />
          </g>
        </svg>,
        link: "/",
        alert: 0,
        enable: true
      },
      {
        title: "User Settings",
        img: <svg width="38.745" height="57.82" viewBox="0 0 38.745 57.82">
          <g id="Group_242" data-name="Group 242" transform="translate(-1442.987 -375.59)">
            <path id="crown-svgrepo-com" d="M17.648,16.684,16.435,27.437a.466.466,0,0,1-.457.413.471.471,0,0,1-.09,0H1.764a.466.466,0,0,1-.084,0,.464.464,0,0,1-.464-.411L0,16.685a.469.469,0,0,1,.024-.209L.054,16.4A.468.468,0,0,1,.9,16.324l3.7,6.432L8.438,11.97a.468.468,0,0,1,.882,0l3.793,10.673,3.637-6.319a.468.468,0,0,1,.847.077l.026.075A.465.465,0,0,1,17.648,16.684Z" transform="translate(1453.534 363.931)" fill="#f7931e" />
            <g id="people-svgrepo-com" transform="translate(1442.987 394.665)">
              <g id="Group_202" data-name="Group 202" transform="translate(0 22.85)">
                <path id="Path_47" data-name="Path 47" d="M58.745,65.6v1.808A3.886,3.886,0,0,1,54.87,71.28h-31A3.886,3.886,0,0,1,20,67.406V65.6c0-4.714,5.489-7.555,10.655-9.815a1.933,1.933,0,0,0,.517-.258,1.2,1.2,0,0,1,1.227.065,12.673,12.673,0,0,0,6.974,2.131,13.16,13.16,0,0,0,6.974-2.066,1.2,1.2,0,0,1,1.227-.065,1.934,1.934,0,0,1,.517.258C53.256,58.043,58.745,60.884,58.745,65.6Z" transform="translate(-20 -55.386)" fill="#F9F9F9" />
              </g>
              <g id="Group_203" data-name="Group 203" transform="translate(9.751 0)">
                <ellipse id="Ellipse_3" data-name="Ellipse 3" cx="9.622" cy="10.655" rx="9.622" ry="10.655" fill="#F9F9F9" />
              </g>
            </g>
          </g>
        </svg>
        ,
        link: "/settings",
        alert: 0
      }
    ]
  };

  componentDidMount() {
    this.setState({ navigate: "" })
  };

  handleChange = e => {
  };

  handleSubmit = e => {
    e.preventDefault();
  };

  gotoPage = (path, locked) => {

    if (!locked) {
      this.props.AccessDlgOpen()
      return
    }

    if (this.props.navigate)
      this.props.navigate(path)
    else 
      window.location.href = path

  }

  clickLogout = () => {
    this.props.logout()
    window.location.href = "/"
  }

  render() {
    const { theme, headerMenus } = this.state;
    const { authUser, roleList, userList, permisionList, hideMenus, companies, groups } = this.props
    let userRequests = 0
    let permissions = {'p-3': true}

    userList.map(user => {
      if (user.pk === authUser.pk || !authUser.json_obj || !user.json_obj) return user
      if (user.json_obj.group_id === authUser.json_obj.group_id && user.is_waiting) userRequests++
      return user
    })

    headerMenus[1].alert = userRequests

    const authRole = roleList.filter(role => role.pk === authUser.user_role)[0]

    if (authRole)
      permisionList.filter(item => authRole.permissions["per-" + item.pk]).map(permission => permissions["p-" + permission.assign_menu] = true)

    if (!permissions['p-0'] && permissions['p-5']) {
      permissions['p-0'] = true
      headerMenus[0].title = "View My Team"
    }

    let companyName = '';
    const authUserGroup = groups.find(g => g.pk === authUser?.json_obj?.group_id);
    if (authUserGroup) {
      companyName = companies?.find(c => c.tag_name === authUserGroup.tag_name)?.name || '';
    }

    return (
      <ThemeProvider theme={theme}>
        <div className="header">
          <div className="head-bar">
            <div className="left-side">
              <h2 onClick={(e) => { e.preventDefault(); this.props.navigate("/dashboard"); }} >
                My Dashboard
              </h2>
            </div>
            <div className="right-side">
              <a href="/" onClick={(e) => { e.preventDefault(); this.clickLogout(); }}>Log Out</a>
              <img src="/static/imgs/CA2-Logo.png" alt="CA Logo" onClick={(e) => { e.preventDefault(); this.props.navigate("/mysystems"); }} />
            </div>
          </div>
          {(!hideMenus) &&
            <div className="main-comp">
              <div className="user-info">
                <img alt="User Avartar" src={authUser.avartar && authUser.avartar.includes("/media/uploads") ? (process.env.REACT_APP_SERVER_URL + authUser.avartar) : '/static/imgs/user-default.png'} />
                <div>
                  <h4>
                    {authUser.username}
                  </h4>
                  <p>
                    Account Type: {roleList.filter(role => role.pk === authUser.user_role * 1)[0] ? roleList.filter(role => role.pk === authUser.user_role * 1)[0].name : "Unset"}
                    {(authUser.user_role * 1 === 1) && <img src="/static/svgs/Super Admin Logo.svg" alt="Super Admin" />}
                  </p>
                  <p>
                    Position: {authUser.role_position}
                  </p>
                  <p>
                    Company: {companyName}
                  </p>
                </div>
              </div>
              <div className="management-board">

                {
                  headerMenus.map((item, i) => {
                    return <div key={i} className="item" onClick={() => this.gotoPage(item.link, permissions["p-" + i])}>
                      {item.img}
                      <label>{item.title}</label>
                      {permissions["p-" + i] && item.alert > 0 && <div className="alert-badge">+{item.alert}</div>}
                      {!permissions["p-" + i] && <div className="locked"><img src="/static/svgs/Lock Logo.svg" alt="Lock Logo" /></div>}
                    </div>
                  })
                }

              </div>
            </div>
          }
        </div>
      </ThemeProvider>
    );
  }
};

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token,
    authUser: state.auth.user,

    userList: state.dashboard.users,
    roleList: state.dashboard.user_roles,
    permisionList: state.dashboard.permissions,
    hideMenus: state.dashboard.hidemenus,
    companies: state.dashboard.companies,
    groups: state.dashboard.groups
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
    AccessDlgOpen: () => dispatch(AccessDlgOpen())
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Comp));
