import React from "react";
import { connect } from "react-redux";
import { AccessDlgOpen } from "../store/actions/dlgs";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import "../styles/MySystem.scss";


class Comp extends React.Component {
    state = {

        theme: createTheme({
            palette: {
                primary: {
                    main: "#F7931E",
                },
                secondary: {
                    main: "#F9F9F9",
                },
            },
        })
    };

    componentDidMount() {
    };

    handleChange = e => {
    };

    handleSubmit = e => {
        e.preventDefault();
    };

    clickItem = (e) => {
        e.preventDefault()
        const { data, locked, AccessDlgOpen, selectRegion } = this.props
        if (locked)
            AccessDlgOpen()
        else
            selectRegion(data)
    }

    render() {
        const { data, locked } = this.props
        const { theme } = this.state;

        return (
            <ThemeProvider theme={theme}>
                {data && <div className="mysystem-card" onClick={this.clickItem}>
                    {data.logo_img && <img src={data.logo_img} alt="svg" />}
                    {!data.logo_img && <h1>{data.name}</h1>}
                    {locked && <div className="locked">
                        <img src="/static/svgs/Lock Logo.svg" alt="svg" />
                    </div>}
                </div>}
            </ThemeProvider>
        );
    }
};

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        token: state.auth.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        AccessDlgOpen: () => dispatch(AccessDlgOpen())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Comp);
