import React from "react";
import { connect } from "react-redux";
import { AccessDlgOpen } from "../store/actions/dlgs";
import withRouter from "../withRouter";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "../styles/MyTeam.scss";


class Comp extends React.Component {
    state = {

        theme: createTheme({
            palette: {
                primary: {
                    main: "#F7931E",
                },
                secondary: {
                    main: "#F9F9F9",
                },
            },
        })
    };

    componentDidMount() {
    };

    handleChange = e => {
    };

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        const { data, clickAssign, index } = this.props
        const { theme } = this.state;
        return (
            <ThemeProvider theme={theme}>
                {data && <div className="myteam-card">
                    <img className="avartar-img" alt="svg" src={data.img} />
                    <h4>
                        {data.name}
                    </h4>
                    <p>
                        Role: {data.Role}
                    </p>
                    <p>
                        Regions Responsible: {data.assignedRegion.length > 0 ? data.assignedRegion.join(" /") : "Unassigned"}
                    </p>

                    {!data.assigned && data.assignable && <a href="/" onClick={(e) => { e.preventDefault(); clickAssign(index) }}>
                        Assign
                    </a>}

                    {data.assigned && data.assignable && <a href="/" onClick={(e) => { e.preventDefault(); this.props.navigate("/reassign?id=" + data.pk) }}>
                        Reassign
                    </a>}

                    {!data.assigned && !data.assignable && <label>
                        UnAssigned
                    </label>}

                    {data.assigned && !data.assignable && <label>
                        Assigned
                    </label>}



                    <img src="/static/imgs/mark-icon.JPG" className="mark-icon" alt="mark icon" />
                    {/* <img src="/static/svgs/Message Logo.svg" className="message-icon" alt="message icon" /> */}
                </div>}
            </ThemeProvider>
        );
    }
};

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        token: state.auth.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        AccessDlgOpen: () => dispatch(AccessDlgOpen())
    };
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Comp));
