import React from "react";
import { connect } from "react-redux";
import { authLogin } from "../store/actions/auth";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "../styles/Teammate.scss";


class Comp extends React.Component {
    state = {

        theme: createTheme({
            palette: {
                primary: {
                    main: "#F7931E",
                },
                secondary: {
                    main: "#F9F9F9",
                },
            },
        })
    };

    componentDidMount() {
    };

    handleChange = e => {
    };

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        const { data, updateStatus, approveStatus } = this.props
        const { theme } = this.state;
        return (
            <ThemeProvider theme={theme}>
                {data && <div className={"teammate-card" + ((approveStatus==="approved")?" approved-card":"") + ((approveStatus==="declined")?" declined-card":"")}>
                    {!approveStatus && <>
                        <div className="user-info">
                            <img src={data.avartar && data.avartar.includes("/media/uploads")?(data.avartar):'/static/imgs/user-default.png'} alt="user info" />
                            <div>
                                <h4>
                                    {data.name} {approveStatus}
                                </h4>
                                <p>Role: {data.Role}
                                </p>
                                <p>
                                    Email: {data.Email}
                                </p>
                            </div>
                        </div>
                        <div className="control-buttons">
                            <div className="approve" onClick={()=>updateStatus(data.id, "approved")}>
                                <img src="/static/svgs/Approved Logo white.svg" alt="approved" />

                            </div>
                            <div className="decline" onClick={()=>updateStatus(data.id, "declined")}>
                                <img src="/static/svgs/Denied Logo white.svg" alt="denied" />
                            </div>
                        </div>
                    </>}
                    {approveStatus === "approved" && <>
                        <h2>Approved</h2>
                        <p>User Profile Added To Manage My Team</p>
                    </>}

                    {approveStatus === "declined" && <>
                        <h2>Declined</h2>
                    </>}
                </div>}
            </ThemeProvider>
        );
    }
};

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        token: state.auth.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        login: (username, password) => dispatch(authLogin(username, password))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Comp);
