import React from "react";
import {
  Grid
} from "semantic-ui-react";
import axios from "axios";
import { connect } from "react-redux";
import { authLogin } from "../store/actions/auth";
import Button from '@mui/material/Button';
import withRouter from "../withRouter";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "../styles/Login.scss";
import { openError } from "../store/actions/notification";


class Comp extends React.Component {
  state = {
    useremail: "",
    theme: createTheme({
      palette: {
        primary: {
          main: "#F7931E",
        },
        secondary: {
          main: "#F9F9F9",
        },
      },
    }),
    logoutFlag: false
  };
  componentDidMount() {

    let { logoutFlag } = this.state
    const urlParams = new URLSearchParams(window.location.search);
    logoutFlag = urlParams.get("logout")
    this.setState({ logoutFlag })
    if (logoutFlag) {
      setTimeout(() => {
        this.setState({ logoutFlag: false })
      }, 2000);
    }

  };
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };


  forgotPwd = (e) => {
    e.preventDefault();
    let { useremail } = this.state
    console.log(useremail)
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/user/create-otp/`, {
        useremail
      })
      .then(res => {
        if (res.data.status === 200)
          this.props.navigate("/reset-pwd")
      })
      .catch(() => {
        setTimeout(() => {
          this.props.openError(true, "Update Failed!", "There was an error happened while generating otp.", "Just now")
        }, 300);
      });
    // this.props.navigate("/reset-pwd")
  }


  render() {
    const { error } = this.props;
    const { theme, logoutFlag, useremail } = this.state;


    return (
      <ThemeProvider theme={theme}>
        <Grid
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 720, height: '100%' }}>
            {error && <p>{this.props.error.message}</p>}
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
              <div className="form-container">
                <div className="up-center-dev flex-center">
                  <img src="/static/imgs/CA2-Logo.png" alt="svg" />
                  <h3 className="client-access">Forgot Password?</h3>
                  <p className="client-access-below ">Provide us your email address and we will send you a link to reset your password</p>
                </div>
                <div className={"down-center-div flex-center " + (logoutFlag ? "hide" : "")}>
                  <br />
                  <br />
                  <TextField label="Email" name="useremail" variant="standard" className="custom-field" value={useremail} onChange={this.handleChange} />
                  <br />
                  <div className="sign-in-btn hover-btn">
                    <Button variant="outlined" onClick={this.forgotPwd.bind(this)}>Reset Password</Button>
                  </div>
                  <br />
                  <br />
                  <label className="next-btn" onClick={() => this.props.navigate("/login")}>
                    Back
                  </label>
                </div>
              </div>
            </Box>
          </Grid.Column>
        </Grid>
      </ThemeProvider>
    );
  }
};
const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token
  };
};
const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => dispatch(authLogin(username, password)),
    openError: (open, title, content, timeStr) => dispatch(openError(open, title, content, timeStr))
  };
};
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Comp));
