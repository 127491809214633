import React from "react";
import { connect } from "react-redux";
import { authLogin } from "../store/actions/auth";
import withRouter from "../withRouter";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import "../styles/Main.scss";

import Header from "../components/Header";
import MySystem from "../components/SystemComp";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

class Comp extends React.Component {
  state = {
    username: "",
    password: "",
    theme: createTheme({
      palette: {
        primary: {
          main: "#F7931E",
        },
        secondary: {
          main: "#F9F9F9",
        },
      },
    }),
    logoutFlag: false,
    fromLogin: "",

    systemList: [
      {
        img: "Artesis Logo.png",
        locked: false
      },

      {
        img: "Genie ai.png",
        locked: true
      },

      {
        img: "WiSysPro.png",
        locked: false
      },

      {
        title: "MSP 2",
        locked: true
      },
    ]
  };

  componentDidMount() {
    let { logoutFlag } = this.state
    const urlParams = new URLSearchParams(this.props.location.search);
    logoutFlag = urlParams.get("logout");
    const fromLogin = urlParams.get("from");
    this.setState({ logoutFlag, fromLogin });

    if (logoutFlag) {
      setTimeout(() => {
        this.setState({ logoutFlag: false });
      }, 2000);
    }
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { username, password } = this.state;
    this.props.login(username, password);
  };

  selectRegion = (system, asset) => {
    this.props.navigate("/select-region?system=" + system.pk);
  }

  selectToRadarChart = (id)=> this.props.navigate(`/radarchart?asset=${id}`)

  render() {
    const { theme, fromLogin } = this.state;
    const { assetList, authUser, subLocationList, systemList } = this.props;

    const tableRows = [];

    if (authUser.json_obj && assetList.length > 0) {
      const assets = assetList.filter(asset => asset.json_obj.group_id === authUser.json_obj.group_id);
      assets.map(asset => {
        const location = subLocationList.find(location => (location.pk * 1 === asset.sub_location * 1))
        if (location) {
          if (!((location.pos_lng > -180 && location.pos_lng < 180) && (location.pos_lat > -90 && location.pos_lat < 90))) return false;
        } else {
          return false;
        }
        const systemObjs = systemList.filter(system => asset.json_obj.systems?.includes(system.pk)).map(item => item.name)
        tableRows.push({ ...asset, location, systemObjs })
      })
    }

    return (
      <ThemeProvider theme={theme}>
        <div
          style={{ height: "100%", minHeight: "100vh" }}
        >
          <Header history={this.props.history} />
          <div className="header-content">
            <div className="left">
            </div>
            <div className="center">
              <h2>
                My Assets
              </h2>
            </div>
            <div className="right" >
              {fromLogin !== "login" && <label onClick={() => this.props.navigate(-1)}>
                Back
              </label>}
            </div>
          </div>
          <div className="main-content">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Asset ID</StyledTableCell>
                    <StyledTableCell>Systems</StyledTableCell>
                    <StyledTableCell align="right">Location</StyledTableCell>
                    <StyledTableCell align="right">Longitude</StyledTableCell>
                    <StyledTableCell align="right">Latitude</StyledTableCell>
                    {/* <StyledTableCell align="right">Status</StyledTableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableRows.map((row) => (
                    <StyledTableRow key={row.pk} onClick={this.selectToRadarChart.bind(this, row.pk)}>
                      <StyledTableCell>
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.systemObjs.join(", ")}
                      </StyledTableCell>
                      <StyledTableCell align="right">{row?.location?.name ? row.location.name : "_"}</StyledTableCell>
                      <StyledTableCell align="right">{row?.location?.pos_lng ? row.location.pos_lng : "_"}</StyledTableCell>
                      <StyledTableCell align="right">{row?.location?.pos_lat ? row.location.pos_lat : "_"}</StyledTableCell>
                      {/* <StyledTableCell align="right" className="success">No Alarm</StyledTableCell> */}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </ThemeProvider>
    );
  }
};

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token,
    systemList: state.dashboard.systems,
    assetList: state.dashboard.assets,
    locationList: state.dashboard.locations,
    subLocationList: state.dashboard.sublocations,

    roleList: state.dashboard.user_roles,
    permisionList: state.dashboard.permissions,
    authUser: state.auth.user
  };
};
const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => dispatch(authLogin(username, password))
  };
};
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Comp));
