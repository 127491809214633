import React from "react";
import { connect } from "react-redux";
import { authLogin } from "../store/actions/auth";
import { approveUser } from "../store/actions/users";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import "../styles/Main.scss";
import withRouter from "../withRouter";

import Header from "../components/Header";
import Teammate from "../components/Teammate";

class Comp extends React.Component {
  state = {
    username: "",
    password: "",
    theme: createTheme({
      palette: {
        primary: {
          main: "#F7931E",
        },
        secondary: {
          main: "#F9F9F9",
        },
      },
    }),
    logoutFlag: false,

    approveStatus: {}

  };
  componentDidMount() {
    let { logoutFlag } = this.state
    const urlParams = new URLSearchParams(window.location.search);
    logoutFlag = urlParams.get("logout")
    this.setState({ logoutFlag })
    if (logoutFlag) {
      setTimeout(() => {
        this.setState({ logoutFlag: false })
      }, 2000);
    }
  };
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleSubmit = e => {
    e.preventDefault();
    const { username, password } = this.state;
    this.props.login(username, password);
  };

  updateStatus = (pk, status)=>{
    let {approveStatus} = this.state
    approveStatus[`user-${pk}`] = status
    this.setState({approveStatus})
    this.props.approveUser(pk, status)
  }


  render() {
    const { theme, approveStatus } = this.state;
    const {authUser, userList} = this.props
    let teamRequests = []
    userList.map(user=>{
      if (user.pk === authUser.pk || !authUser.json_obj || !user.json_obj) return user
      if(user.json_obj.group_id === authUser.json_obj.group_id && user.is_waiting) {
        teamRequests.push({
            id: user.pk,
            avartar: user.avartar,
            name: user.username,
            Role: user.role_position,
            Email: user.email
        })
      }
      return user
    })

    return (
      <ThemeProvider theme={theme}>
        <div
          style={{ height: "100%", minHeight: "100vh" }}
        >
            <Header history={this.props.history}/>
            <div className="header-content">
              <div className="left">
                <h2>
                  New Teammate Request &nbsp;&nbsp;&nbsp;
                  <label className="red-label">
                    {teamRequests.filter(item=>!item.status).length<10?`0${teamRequests.filter(item=>!item.status).length}`:teamRequests.filter(item=>!item.status).length}
                  </label>
                </h2>
              </div>
              <div className="center"></div>
              <div className="right">
                <label onClick={() => this.props.navigate(-1)}>
                  Back
                </label>
              </div>
            </div>

            <div className="main-content">
              {teamRequests.map((item, i) =>
                <Teammate index = {i} approveStatus={approveStatus[`user-${item.id}`]} updateStatus={this.updateStatus.bind(this)} key={i} data={item} />
              )}
            </div>
        </div>
      </ThemeProvider>
    );
  }
};

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token,
    authUser: state.auth.user,

    userList: state.dashboard.users,
    roleList: state.dashboard.user_roles,

  };
};
const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => dispatch(authLogin(username, password)),
    approveUser: (id, value) => dispatch(approveUser(id, value))
  };
};
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Comp));
