import axios from "axios";
import * as actionTypes from "./actionTypes";
import { openError } from "./notification"


function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}


export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = token => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token
  };
};



export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};

export const authUser = user => {
  return {
    type: actionTypes.GET_AUTH,
    user
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("expirationDate");
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const checkAuthTimeout = expirationTime => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const authLogin = (username, password) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const csrftoken = readCookie('csrftoken');
      const headers = { "Content-Type": "multipart/form-data", "X-CSRFToken": csrftoken };
      dispatch(authStart());
      axios
        .post(process.env.REACT_APP_SERVER_URL + "/api/user/user-token-auth/", {
          username,
          password
        }, { headers })
        .then(res => {
          const token = res.data.token;
          const expirationDate = new Date(new Date().getTime() + 3600 * 24000);
          localStorage.setItem("token", token);
          localStorage.setItem("expirationDate", expirationDate);

          dispatch(authSuccess(token));
          dispatch(getAuthUser(token));
          resolve(res);
          // dispatch(checkAuthTimeout(3600));
        })
        .catch(err => {
          // dispatch(authFail(err));
          // console.log("err : ", err.response.data.message)
          dispatch(openError(true, "Auth Failed", err.response.data.message))
          reject(err);
      });
    })
  };
};


export const getAuthUser = (token) => {
  return dispatch => {
    const csrftoken = readCookie('csrftoken');
    const headers = { "Authorization": `Token ${token}`, "X-CSRFToken": csrftoken };
    axios({
      method: "get",
      url: process.env.REACT_APP_SERVER_URL + "/api/user/auth_user/",
      headers,
    })
      .then(function (res) {
        //handle success
        const user = res.data
        console.log("auth user : ", user)

        dispatch(authUser(user))

      })
      .catch(function (err) {
        //handle error
        console.log(err)
        dispatch(authUser({}))
        dispatch(logout())
      });
  }

};


export const authSignup = (username, email, password1, password2, company, user_data, uploadFile, callback) => {
  return dispatch => {
    const csrftoken = readCookie('csrftoken');
    var bodyFormData = new FormData();
    bodyFormData.append('username', username);
    bodyFormData.append('email', email);
    bodyFormData.append('password', password1);
    bodyFormData.append('password2', password2);
    bodyFormData.append('role_position', company);
    bodyFormData.append('user_data', JSON.stringify(user_data));
    bodyFormData.append('company', 'company');
    bodyFormData.append('user_role', process.env.REACT_APP_USER_ROLE);
    bodyFormData.append('customer_id', `cus-${Date.now()}`);

    if (uploadFile)
      bodyFormData.append('avartar', uploadFile);

    axios({
      method: "post",
      url: process.env.REACT_APP_SERVER_URL + "/api/user/create",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data", "X-CSRFToken": csrftoken },
    })
      .then(function (res) {
        //handle success
        console.log(res);
        callback({ res, success: "success" });
      })
      .catch(function (res) {
        //handle error
        console.log(res);
        dispatch(authFail(res));
        callback(res);
      });
  };
};

export const authUpdate = (user_id, username, email, password1, password2, company, user_data, uploadFile, callback) => {
  return dispatch => {
    const csrftoken = readCookie('csrftoken');
    const token = localStorage.getItem("token");
    var bodyFormData = new FormData();
    bodyFormData.append('username', username);
    bodyFormData.append('email', email);
    bodyFormData.append('password', password1);
    bodyFormData.append('password2', password2);
    bodyFormData.append('role_position', company);
    bodyFormData.append('user_data', JSON.stringify(user_data));
    bodyFormData.append('company', 'company');
    bodyFormData.append('user_role', process.env.REACT_APP_USER_ROLE);
    bodyFormData.append('customer_id', `cus-${Date.now()}`);

    if (uploadFile)
      bodyFormData.append('avartar', uploadFile);

    axios({
      method: "put",
      url: `${process.env.REACT_APP_SERVER_URL}/api/user/update/${user_id}/`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data", "X-CSRFToken": csrftoken, "Authorization": `Token ${token}` },
    })
      .then(function (res) {
        //handle success
        console.log(res);
        callback({ res, success: "success" });
      })
      .catch(function (res) {
        //handle error
        console.log(res);
        dispatch(authFail(res));
        callback(res);
      });
  };
};

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem("token");
    if (token === undefined) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        dispatch(authSuccess(token));
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      }
    }
  };
};
