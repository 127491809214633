import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { useSelector, useDispatch } from "react-redux";
import "../styles/Dialog.scss"
import * as actionTypes from "../store/actions/actionTypes"


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



function Comp() {

    const openDlg = useSelector((state) => state.dlgs.accessDlg)
    const dispatch = useDispatch()



    return (
        <div>
            <Dialog
                open={openDlg}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => { dispatch({ type: actionTypes.ACCESSDLG_CLOSE }) }}
                aria-describedby="alert-dialog-slide-description"
            >
                <div className='dialog-content filter-dlg'>
                    <img src='/static/svgs/Exit Button.svg' className='btn-exit' alt='Exit Button' onClick={() => dispatch({ type: actionTypes.ACCESSDLG_CLOSE })} />
                    <div className='assignment-success'>                        
                        <img className='avartar' src='/static/svgs/Denied Logo.svg'  alt='Denied Logo'/>
                        <p className='description access-denied'>
                        You Do Not Have Access To<br/> This System
                        </p>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default Comp
