import React from "react";
import {
  Grid
} from "semantic-ui-react";
import { connect } from "react-redux";
import { authLogin } from "../store/actions/auth";
import withRouter from "../withRouter";
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import "../styles/Login.scss"


class LoginForm extends React.Component {
  state = {
    username: "",
    password: "",
    theme: createTheme({
      palette: {
        primary: {
          main: "#F7931E",
        },
        secondary: {
          main: "#ffffff",
        },
      },
    })
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { username, password } = this.state;
    this.props.login(username, password);
  };

  componentDidMount(){
    setTimeout(() => {
      this.props.navigate("/login")
    }, 3000);
  }

  render() {
    // const { error, token } = this.props;
    const { theme } = this.state;


    return (
      <ThemeProvider theme={theme}>
        <Grid
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 572 }}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
              <div className="form-container">

                <div className="up-center-dev flex-center">
                  <img src="/static/imgs/CA2-Logo.png" alt="CA logo" />
                  <h3 className="client-access">Client Access</h3>
                  <p className="client-access-below ">A ViBTECH GENESiS Digital Initiative</p>
                </div>

                <div className="down-center-div flex-center">
                  <p className="logout-success">
                    You have successfully logged out
                  </p>
                </div>

              </div>
            </Box>

          </Grid.Column>
        </Grid>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => dispatch(authLogin(username, password))
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm));
