import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { useSelector, useDispatch } from "react-redux";
import "../styles/Dialog.scss"
import * as actionTypes from "../store/actions/actionTypes"


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const regions = [
    "Central", "East", "West", "North", "South"
]

function Comp() {

    const openDlg = useSelector((state) => state.dlgs.filterDlg);
    const dispatch = useDispatch();
    const [selStatus, setSelStatus] = React.useState(0);
    const [selectedRegion, selectRegion] = React.useState(-1);
    const confirmAssign = () => {
        setSelStatus(1);
        setTimeout(() => {
            dispatch({ type: actionTypes.FILTERDLG_CLOSE })
        }, 1000);
        setTimeout(() => {
            setSelStatus(0)   
        }, 1500);
    }

    return (
        <div>
            <Dialog
                open={openDlg}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => { }}
                aria-describedby="alert-dialog-slide-description"
            >
                <div className='dialog-content filter-dlg'>
                    {selStatus === 0 && <img src='/static/svgs/Exit Button.svg' alt="svg" className='btn-exit' onClick={() => dispatch({ type: actionTypes.FILTERDLG_CLOSE })} />}
                     {selStatus === 1 &&
                        <div className='assignment-success'>
                            <p className='description '>
                                Region Selected
                            </p>
                            <img className='avartar' alt="svg" src='/static/svgs/Approved Logo.svg' />
                        </div>}
                    {selStatus === 0 && <p className='description'>
                        Choose A Region
                    </p>}
                    <div className='select-part'>
                        {selStatus === 0 && regions.map((item, i) => {
                            return <div key={i} className={'select-item' + (selectedRegion === i ? " selected" : "")} onClick={() => selectRegion(i)}>
                                {item}
                            </div>
                        })}
                    </div>
                    {selStatus === 0 && <label className='btn-next' onClick={confirmAssign}>
                        Apply
                    </label>}
                </div>
            </Dialog>
        </div>
    );
}

export default Comp
