import React from "react";
import {
  Grid
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { authLogin } from "../store/actions/auth";
import { openError } from "../store/actions/notification";
import withRouter from "../withRouter"

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from "axios";
import "../styles/CA2Login.scss"

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

class LoginForm extends React.Component {
  state = {
    username: "",
    password: "",
    theme: createTheme({
      palette: {
        primary: {
          main: "#F7931E",
        },
        secondary: {
          main: "#F9F9F9",
        },
      },
    }),
    showPassword: false,
    logoutFlag: false
  };

  componentDidMount() {

    let { logoutFlag } = this.state
    const urlParams = new URLSearchParams(this.props.location?.search);
    logoutFlag = urlParams.get("logout")
    this.setState({ logoutFlag })
    if (logoutFlag) {
      setTimeout(() => {
        this.setState({ logoutFlag: false })
      }, 2000);
    }

  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { username, password } = this.state;
    console.log("username and password : ", username, password)
    if (!username || !password) {
      return;
    }
    const csrftoken = getCookie('csrftoken');
    const headers = { "Content-Type": "multipart/form-data", "X-CSRFToken": csrftoken };
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/user/get_role_id/`, {
        useremail: username,
        }, {headers})
        .then(res => {
          console.log("res data", res.data);
          if (res.data.role_status) {
            this.createOTP(username, password);
          } else {
            this.props.login(username, password)
            .then(() => {
              this.setState({ showPassword: false }); // Reset the showPassword state on successful login
            })
            .catch(err => {
              this.setState({ showPassword: true }); // Show the password on error
              console.error('Error:', err);
            });
          }
        })
      .catch((error) => {
        this.props.openError(true, "Auth Failed", "User not found", "Just now")
        console.error('Error:', error.response ? error.response.data : error.message);
      });
  };

  createOTP = (useremail, password) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/user/create-otp/`, {
        useremail,
        password,
        fromlogin: true
      })
      .then(res => {
        if (res.data.status === 200) {
          const { otp_token } = res.data;
          const params = { useremail, password, otp_token };
          console.log('params: ', params);
          this.props.navigate("/log/verify-otp", { state: params })
        }
      })
      .catch((error) => {
        console.log('createOTP error: ', error);
        setTimeout(() => {
          this.props.openError(true, "Update Failed!", "There was an error happened while generating otp.", "Just now")
        }, 300);
      });
  }

  cancelSubmit = () => {
    this.setState({ username: "", password: "" })
  }

  handleClickShowPassword = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const { error, token } = this.props;
    const { theme, logoutFlag, username, password, showPassword  } = this.state;

    if (token) {
      return <Navigate to="/mysystems?from=login" />;
    }
    return (
      <ThemeProvider theme={theme}>
        <div className="login-page">
          <section className="header-section">
            <img src={require("../assets/imgs/vtg-logo.webp")} alt="logo" />
          </section>

          <section className="main-section">
            <div className="main-content">
              <div className="left-side">
                <h1>
                  Welcome to Client Access II
                </h1>
                <h2>
                  Your Online Condition Monitoring System
                </h2>
              </div>
              <div className="right-side">
                <TextField label="Email" variant="standard" autoComplete="new-password" className="custom-field" value={username} name="username"
                  onChange={this.handleChange}
                />
                <br />
                {/* <TextField label="Password" variant="standard" autoComplete="new-password" type="password" className="custom-field" value={password} name="password"
                  onChange={this.handleChange}
                /> */}
                <TextField label="Password" variant="standard" autoComplete="new-password" type={showPassword ? 'text' : 'password'} className="custom-field" value={password} name="password" 
                  onChange={this.handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <div className="sign-in-btn">
                  <Button variant="outlined" onClick={this.cancelSubmit.bind(this)}>Cancel</Button>
                  <Button variant="outlined" onClick={this.handleSubmit.bind(this)}>Login</Button>
                </div>
                <FormControlLabel
                  value="start"
                  control={<Checkbox />}
                  label="Remember Me"
                  labelPlacement="start"
                  className="remember-me"
                />
                <p className="forgot-password" onClick={() => this.props.navigate("/forgot-pwd")} >Forgot Password?</p>
                <p className="sign-up">
                  Don't have an Account? <a href="/" onClick={(e) => { e.preventDefault(); this.props.navigate("/signup"); }}>Sign Up</a> here.
                </p>
              </div>
            </div>
          </section>

        </div>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => dispatch(authLogin(username, password)),
    openError: (open, title, content, dateTime) => dispatch(openError(open, title, content, dateTime))
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm));
