import React from "react";
import {
  Grid
} from "semantic-ui-react";

import Box from '@mui/material/Box';
import withRouter from "../withRouter";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "../styles/Login.scss"


class Comp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

      theme: createTheme({
        palette: {
          primary: {
            main: "#F7931E",
          },
          secondary: {
            main: "#F9F9F9",
          },
        },
      }),
    }
  }

  render() {
    // const { token } = this.props;
    const { theme } = this.state

    return (
      <ThemeProvider theme={theme}>
        <Grid
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 1024, height: '100%', width: '96%' }}>
            {/* {error && <p>{this.props.error.message}</p>} */}
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
              <div className="form-container">
                <div className="up-center-dev flex-center">
                  <img src="/static/imgs/CA2-Logo.png" alt="svg" onClick={() => this.props.navigate("/")} />
                  <h3 className="sign-up-account"> TERMS AND CONDITIONS - USE OF VIBTECH TRAVEL  </h3>
                </div>
                <div className="down-center-div flex-center terms-condition">
                  <h5>MOBILE APP</h5>
                  <p>
                    The VIBTECH Mobile App (hereafter: "Mobile App" or "App") or VIBTECH website (hereafter: "Website" or "Site") is provided to you by Vibtech Genesis Pte Ltd (hereafter: "Vibtech", "we", or "us"). These Terms and Conditions (hereafter: "Terms") apply to all customers, including persons who access this Mobile App or Website (hereafter: "Customers" or "you"). Please read these Terms carefully before you access or use this Mobile App or Website.
                  </p>
                  <h5>
                    1.	Agreement
                  </h5>
                  <p>
                    1.1 By using this Mobile App or Website, you acknowledge that you have read and understood the Terms set out below and agree to be bound by these Terms, our Privacy Policy, all applicable laws and regulations and responsible for compliance with any applicable local laws. If you do not agree unconditionally with any of these Terms, you have no right to use this Mobile App or Website. By the use of the service, you agree with all the Terms. The materials contained in this Mobile App or Website are protected by applicable copyright and trademark law.
                  </p>
                  <p>
                    1.2 Vibtech's terms may be amended periodically. As you are bound by these Terms, please review our terms periodically.
                  </p>
                  <h5>
                    2.	Personal Information Collection Statement
                  </h5>
                  <p>
                    2.1 In compliance with the requirements of the Singapore Personal Data Protection Act 2012 (PDPA), we will comply with the obligations and requirements of the Act, proper handling, and management of personal data of the user.
                  </p>
                  <p>
                    2.2 Customers must ensure that the personal information provided to us is updated, complete and accurate in all respects.
                  </p>
                  <h5>
                    3.	Amendment of Terms and Conditions
                  </h5>
                  <p>
                    3.1 We reserve the right to amend these Terms at any time without prior notice. In case of disputes, we have the right of the final decision.
                  </p>
                  <p>
                    3.2 We reserve the right to refuse the supply of the products and services. All provision of our products and services must comply with these Terms, and the Customer must read and fully agree with the Terms before execution of the purchase.
                  </p>
                  <p>
                    3.3 We may at any time carry out necessary maintenance to the Website, Mobile App, and/or our products and services to ensure the quality of service to all customers.
                  </p>

                  <h5>
                    4.   Disclaimer
                  </h5>
                  <p>
                    4.1 We shall not be responsible for any damages and loss suffered by any party due to errors caused by our telecommunications service provider.
                  </p>
                  <p>
                    4.2 We shall not be responsible for any failure or setting errors to customer’s mobile device caused by failure to access the internet services.
                  </p>
                  <p>
                    4.3 We shall not be responsible for short-circuit or fire, casualties or losses to the Customer resulting from any inappropriate, carelessness, overuse or misplacement of mobile devices by the Customer. We advise customers to use electronic devices with care and caution.
                  </p>
                
                  <p>
                    4.4 We may modify or suspend the services, wholly or partially, without notice where such modification or suspension is deemed necessary by us (e.g. for maintenance, upgrading, security, emergency or other valid reasons). All reasonable efforts shall be made to
                    minimise such service disruptions; however, some interruption may be inevitable. We will notify you where and as soon as it is practicable to do so.
                  </p>
                  <p>
                    4.5 We may, from time to time and without notice, change the products and service in order to comply with applicable safety, regulatory or statutory requirements, provided that such changes do not materially affect the nature or scope of the products and service or the charges. We do not represent or warrant that the products themselves nor the operation (or related products or services, including those of third parties) will be uninterrupted, timely, secure or error-free or that it will meet your specific requirements.
                  </p>
                  <p>
                    4.6 The quality and availability of the products and services are subject to certain limitations and circumstances beyond our reasonable control including, but not limited to, physical, geographic and atmospheric conditions.
                  </p>
                
                  <h5>
                    5. Intellectual Property Rights
                  </h5>
                  <p>
                    All software and contents (defined here as any texts, audios, music, images, photos and  pictures, or any contents containing the above) of this Mobile App or Website are our intellectual property works and hence are protected by intellectual property rights, copyrights laws, and international conventions. Our Mobile App and Website are for personal use only and not for commercial use. Contents of the Website can be used only when our explicit authorisation and/or our third party licensor is obtained. No content of the Website can be published in print, online media, reproduced, sold, or licensed without our consent or authorization. Any unauthorised act may result in civil liabilities or criminal sanctions.
                  </p>
                  <h5>
                    6.  Governing Laws
                  </h5>
                  <p>
                    This agreement will be construed in accordance with the laws of Hong Kong SAR and the parties agreed to the exclusive jurisdiction of the Hong Kong courts in the event of any dispute.
                  </p>
                  <h5>
                    7.  Limit of Liability
                  </h5>
                  <p>
                    7.1 We exclude all liability or responsibility for any cost, claim, damage or loss to a customer or any person whether direct or indirect of any kind including loss of revenue, loss of profits or any consequential loss in contract, tort or under any statue or otherwise (including negligence arising out of or in any way related to this Agreement). Save that nothing herein shall limit our liability for death or personal injury arising from our negligence.
                  </p>
                  <p>
                    7.2 We will use our reasonable endeavours to ensure that the contents and products or services in this Website/ Mobile Application are accurate and complete but cannot guarantee that all the descriptions of the contents and products or services are accurate, complete, updated and free of error. We shall not be liable for any errors of information posted in our Mobile App and Website. We shall have the right to, from time to time, modify or correct any errors in the Website / Mobile Application or in any product sales materials, quotations,
                    orders or other materials without incurring any liability.
                  </p>
               
                  <p>
                    7.3 Colour and photo of products displayed on our Mobile App and Website are for reference only.
                  </p>
                
                  <p>
                    7.4 We shall not be held liable for any delay or failure in our performance or any breach of contract which was directly or indirectly caused by any of the following reasons: acts of God, fire, flood, accident, riot, war, terrorist attack, government intervention, trade embargo,
                    strike, labor dispute, equipment failure (including without limitation, Internet access failure) or other factors beyond our reasonable control.
                  </p>
                  <p>
                    7.5 We do not guarantee that the servers of providing support to our Mobile App and Website do not contain viruses or other harmful components. Customers must ensure that they adequately protect themselves and any of their equipment, software, and data by taking appropriate steps such as using up-to-date antivirus and firewall software.
                  </p>
                  <p>
                    7.6 We reserve the right to modify and revise all terms and conditions herein contained from time to time without prior notice.
                  </p>
                  <p>
                    7.7 We reserve the right to withdraw or modify any services from time to time without prior notice. If this Mobile App or Website becomes unavailable for use at any time or a period of time for whatever reasons, we shall under no circumstances be liable.
                  </p>
                  <p>
                    7.8 In case of disputes, our decision shall be final and conclusive.
                  </p>
                  
                  <h5>
                    8. Governing Version
                  </h5>
                  <p>
                    In the event of any discrepancy between the English and Chinese versions of the above terms and conditions, the English version shall prevail.
                  </p>
                </div>
              </div>
            </Box>
          </Grid.Column>
        </Grid>
      </ThemeProvider>
    );
  }
}

export default withRouter(Comp);
