import React from "react";
import {
  Grid
} from "semantic-ui-react";
import { connect } from "react-redux";
import { authLogin } from "../store/actions/auth";
import Box from '@mui/material/Box';
import withRouter from "../withRouter";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "../styles/Login.scss"


class Comp extends React.Component {
  state = {
    username: "",
    password: "",
    theme: createTheme({
      palette: {
        primary: {
          main: "#F7931E",
        },
        secondary: {
          main: "#ffffff",
        },
      },
    })
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });

  };

  handleSubmit = e => {
    e.preventDefault();
    const { username, password } = this.state;
    this.props.login(username, password);
  };

  componentDidMount() {
    // setTimeout(() => {
    //   this.props.navigate("/login");
    // }, 10000);
  };

  render() {

    const { error } = this.props;
    const { theme } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <Grid
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 572 }}>
            {error && <p>{this.props.error.message}</p>}
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
              <div className="form-container">
                <div className="up-center-dev flex-center">
                  <img src="/static/imgs/CA2-Logo.png" alt="CA Logo" />
                  <p className="client-access-below mt-3">Registration Complete!</p>
                </div>
                <div className="down-center-div flex-center">                
                  <p className="logout-success">
                     Your email has been verified successfully, Please wait for approval from your respective Super Admin
                  </p>
                </div>
                <p className="sign-up" onClick={() => this.props.navigate("/login")}>
                  Go Back To Login Page
                </p>
              </div>
            </Box>

          </Grid.Column>
        </Grid>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => dispatch(authLogin(username, password))
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Comp));
